import { Typography } from "@mui/material";
import VerticalBarChartComponent from "./VerticalBarChartComponent";

interface AgeGenderChartComponentProps {
    data1: any
    label1: string
    data2: any
    label2: string
    title: string
}

export default function AgeGenderChartComponent({ data1, label1, data2, label2, title }: AgeGenderChartComponentProps) {
    return (
        <>
            <div style={{ display: 'flex', maxHeight: '27vh' }}>
                <div style={{ width: '40%' }}>
                    <VerticalBarChartComponent
                        data={data1} title={label1} cartesianGrid={false} hideAxis withoutLegend color={'#5171dc'}
                        chartMargin={{ top: 0, right: 20, left: 0, bottom: 10 }} withLabel={false}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-around', alignItems: 'center', paddingTop: '1rem', marginBottom: '-.25rem' }}>
                    {
                        data1.map((data: any) => {
                            return (
                                <Typography color={'text.primary'} textAlign={'center'} fontSize={12}>
                                    {data.name}
                                </Typography>
                            )
                        })
                    }
                </div>
                <div style={{ width: '40%' }}>
                    <VerticalBarChartComponent
                        data={data2} title={label2} cartesianGrid={false} hideAxis withoutLegend color={'#ef4040'}
                        chartMargin={{ top: 0, right: 0, left: 20, bottom: 10 }} withLabel={false}
                    />
                </div>
            </div>
        </>

    )
}