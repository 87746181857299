import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { defaultEducacaoData } from '../fakeData/educacaoData';
import DashHeader from '../components/DashHeader';
import CustomCard from '../components/CustomCard';
import PieChartComponent from '../components/charts/PieChartComponent';
import VerticalBarChartComponent from '../components/charts/VerticalBarChartComponent';
import MapComponent from '../components/map/MapComponent';
import LineChartComponent from '../components/charts/LineChartComponent';
import { ApiUrl } from '../constants/Link';
import { EducacaoType } from '../types/EducacaoType';
import axios from 'axios';
import { usePeriodContext } from '../provider/PeriodProvider';
import moment from 'moment';

export default function EducacaoDash() {
	const { start, end } = usePeriodContext();
	const [dash, setDash] = useState<EducacaoType>(defaultEducacaoData)

	const [mapView, setMapView] = useState(0)

	const intervalTime = 10000;

	let intervalId: string | number | NodeJS.Timeout | undefined;

	useEffect(() => {
		axios.get(`${ApiUrl}/dashboard/educacao?filter=1${start ? `&start=${moment(start).format('YYYY-MM-DD')}` : ''}${end ? `&end=${moment(end).format('YYYY-MM-DD')}` : ''}`).then((response) => {
			if (response?.data?.success) {
				setDash(response?.data?.data)
			}
		})
	}, [start, end])

	const toggleMapView = () => {
		setMapView((prevState) => {
			return prevState === 0 ? 1 : 0
		});
	};

	useEffect(() => {
		intervalId = setInterval(toggleMapView, intervalTime);
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	const handleChangeMapView = () => {
		clearInterval(intervalId);
		toggleMapView()
	}

	return (
		<Grid container minHeight={'100vh'} overflow={'hidden'} bgcolor={'background.default'}>
			<Grid container item md={12} bgcolor={'background.default'} height={"10vh"}>
				<DashHeader title={dash.title} insights={dash.insights} />
			</Grid>

			<Grid container item xs={6} md={2} borderColor={'divider'} height={"90vh"}>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.qualidade.title ?? "QUALIDADE DA EDUCAÇÃO"}
						value={dash.qualidade.value}
						icon={dash.qualidade.icon}
						color={dash.qualidade.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.total_alunos.title ?? "TOTAL DE ALUNOS"}
						value={dash.total_alunos.value}
						icon={dash.total_alunos.icon}
						color={dash.total_alunos.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.vagas_disponiveis.title ?? "VAGAS DISPONÍVEIS"}
						value={dash.vagas_disponiveis.value}
						icon={dash.vagas_disponiveis.icon}
						color={dash.vagas_disponiveis.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.matriculas_pendentes.title ?? "MATRÍCULAS EM ESPERA"}
						value={dash.matriculas_pendentes.value}
						icon={dash.matriculas_pendentes.icon}
						color={dash.matriculas_pendentes.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.ausencia.title ?? "AUSÊNCIA DOS ALUNOS"}
						value={dash.ausencia.value}
						icon={dash.ausencia.icon}
						color={dash.ausencia.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.qualidade_merenda.title ?? "QUALIDADE DA MERENDA"}
						value={dash.qualidade_merenda.value}
						icon={dash.qualidade_merenda.icon}
						color={dash.qualidade_merenda.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.pontuacao_idbe.title ?? "PONTUAÇÃO NO IDBE"}
						value={dash.pontuacao_idbe.value}
						icon={dash.pontuacao_idbe.icon}
						color={dash.pontuacao_idbe.color}
					/>
				</Grid>
			</Grid>

			<Grid
				container item xs={6} md={10}
				bgcolor="#c9c9c9" borderColor={'divider'}
				display={'flex'} flexDirection={'column'} justifyContent={'space-between'} maxHeight={'90vh'}
			>
				<Grid container item xs={12} md={8} minHeight={'100%'} height={'90vh'}>
					<Grid
						container item xs={6} md={12}
						bgcolor={'background.default'} borderColor={'divider'}
						height={'15vh'}
					>
						<Grid
							item xs={6} md={4}
							bgcolor={'background.default'} borderLeft={0} borderTop={0}
							border={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
							height={'15vh'} borderColor={'divider'}
						>

							<CustomCard
								title={dash.alfabetizacao.title ?? "ALFABETIZAÇÃO DA POPULAÇÃO"}
								value={dash.alfabetizacao.value}
								icon={dash.alfabetizacao.icon}
								color={dash.alfabetizacao.color}
							/>

						</Grid>
						<Grid
							item xs={6} md={4}
							bgcolor={'background.default'} borderLeft={0} borderTop={0}
							border={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
							height={'15vh'} borderColor={'divider'}
						>

							<CustomCard
								title={dash.aprovacao.title ?? "APROVAÇÃO DAS ESCOLAS"}
								value={dash.aprovacao.value}
								icon={dash.aprovacao.icon}
								color={dash.aprovacao.color}
							/>

						</Grid>
						<Grid
							item xs={6} md={4}
							bgcolor={'background.default'} borderLeft={0} borderTop={0}
							border={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
							height={'15vh'} borderColor={'divider'}
						>

							<CustomCard
								title={dash.evasao.title ?? "TAXA DE EVASÂO ESCOLAS"}
								value={dash.evasao.value}
								icon={dash.evasao.icon}
								color={dash.evasao.color}
							/>

						</Grid>
					</Grid>
					<Grid container item xs={12} md={12} height={'50vh'}>
						<Grid
							container item xs={6} md={6}
							bgcolor={'background.default'} borderLeft={0} borderTop={0}
							height={'50vh'} borderColor={'divider'}
						>
							<Grid
								item xs={6} md={12} padding={1}
								bgcolor={'background.default'} borderLeft={0} borderTop={0}
								height={'25vh'} border={2} borderColor={'divider'}
							>
								<VerticalBarChartComponent
									data={dash.escolas_aprovacao_chart.data}
									title={"TOP 5 ESCOLAS COM MAIOR ÍNDICE DE APROVAÇÃO"}
									chartMargin={{
										top: 5,
										right: 0,
										left: 20,
										bottom: 15
									}} withoutLegend hideXAxis
								/>
							</Grid>
							<Grid
								item xs={6} md={12} padding={1}
								bgcolor={'background.default'} borderLeft={0} borderTop={0}
								height={'25vh'} border={2} borderColor={'divider'}
							>
								<VerticalBarChartComponent
									data={dash.escolas_reprovacao_chart.data}
									title={"TOP 5 ESCOLAS COM MAIOR ÍNDICE DE REPROVAÇÃO"}
									chartMargin={{
										top: 5,
										right: 0,
										left: 20,
										bottom: 15
									}} withoutLegend hideXAxis
								/>
							</Grid>
						</Grid>
						<Grid
							container item xs={6} md={6}
							bgcolor={'background.default'} borderLeft={0} borderTop={0}
							height={'50vh'} borderColor={'divider'}
						>
							<Grid
								item xs={6} md={12} padding={1}
								bgcolor={'background.default'} borderLeft={0} borderTop={0}
								height={'25vh'} border={2} borderColor={'divider'}
							>
								<VerticalBarChartComponent
									data={dash.escolas_matriculas_chart.data}
									title={"TOP 5 ESCOLAS COM MAIS MATRÍCULAS EM ESPERA"}
									chartMargin={{
										top: 5,
										right: 0,
										left: 20,
										bottom: 15
									}} withoutLegend hideXAxis
								/>
							</Grid>
							<Grid
								item xs={6} md={12} padding={1}
								bgcolor={'background.default'} borderLeft={0} borderTop={0}
								height={'25vh'} border={2} borderColor={'divider'}
							>
								<VerticalBarChartComponent
									data={dash.escolas_vagas_chart.data}
									title={"TOP 5 ESCOLAS COM MAIS VAGAS DISPONÍVEIS"}
									chartMargin={{
										top: 5,
										right: 0,
										left: 20,
										bottom: 15
									}} withoutLegend hideXAxis
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						container item xs={6} md={12}
						bgcolor={'background.default'} borderColor={'divider'}
						height={'25vh'}
					>
						<Grid
							item xs={6} md={6} padding={1}
							bgcolor={'background.default'} border={2} borderColor={'divider'}
							height={'25vh'}
						>
							<PieChartComponent
								title={"TOTAL DE ESCOLAS"}
								data={dash.escolas_chart.data}
								other={dash.escolas_chart.other}
								//direction={'row'}
								radius={45}
							/>
						</Grid>
						<Grid
							item xs={6} md={6} padding={1}
							bgcolor={'background.default'} border={2} borderColor={'divider'}
							height={'25vh'}
						>
							<PieChartComponent
								title={"FUNCIONÁRIOS"}
								data={dash.funcionarios_chart.data}
								other={dash.funcionarios_chart.other}
								//direction={'row'}
								radius={45}
							/>
						</Grid>

					</Grid>
				</Grid>

				<Grid container item xs={12} md={4} minHeight={'90vh'}>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'}
						height={'40%'} border={2} borderColor={'divider'}
					>
						<MapComponent
							textColor={'text.primary'}
							title={'OCORRÊNCIA POR LOCALIDADE'}
							markers={mapView == 0 ? dash.locais.data : dash.locais.data2}
							containerStyle={{
								width: '100%',
								height: '95%',
							}} canSwap handleChangeMapView={handleChangeMapView}
						// withLegend legend='left'
						// legendComponent={
						// 	<Box style={{ height: '100%' }}>
						// 		<Typography textAlign={"center"} fontSize={10} color={'text.primary'}>NÍVEL DE QUALIDADE</Typography>
						// 	</Box>
						// }
						/>
					</Grid>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'}
						height={'40%'} border={2} borderColor={'divider'}
					>
						<VerticalBarChartComponent
							data={dash.materias_aprovacao_chart.data}
							title={"MATÉRIAS POR ORDEM DE MAIOR APROVAÇÃO"}
							chartMargin={{
								top: 5,
								right: 0,
								left: 20,
								bottom: 15
							}} withoutLegend hideXAxis
						/>
					</Grid>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'}
						height={'20%'} border={2} borderColor={'divider'}
					>
						<LineChartComponent
							data={dash.evasao_chart.data}
							title={"TAXA DE EVASÃO ESCOLAR"}
							withoutLegend
							chartMargin={{
								top: 10,
								right: 30,
								left: -20,
								bottom: 0
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid >
	)
}
