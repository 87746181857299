import { Routes, Route } from "react-router-dom";
import SaudeDash from "./pages/SaudeDash";
import DashSelection from "./pages/DashSelection";
import EducacaoDash from "./pages/EducacaoDash";
import MobilidadeDash from "./pages/MobilidadeDash";
import SegurancaDash from "./pages/SegurancaDash";
import DefesaCivilDash from "./pages/DefesaCivilDash";
import ZeladoriaDash from "./pages/ZeladoriaDash";
import PopulacaoDash from "./pages/PopulacaoDash";

export function Router() {
    return (
        <Routes>
            <Route path="/" element={<DashSelection />} />
            <Route path="/saude" element={<SaudeDash />} />
            <Route path="/educacao" element={<EducacaoDash />} />
            <Route path="/zeladoria" element={<ZeladoriaDash />} />
            <Route path="/defesa-civil" element={<DefesaCivilDash />} />
            <Route path="/seguranca" element={<SegurancaDash />} />
            <Route path="/mobilidade" element={<MobilidadeDash />} />
            <Route path="/populacao" element={<PopulacaoDash />} />
        </Routes>
    )
}