import { Box, Fade, Grid, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { dashs } from '../provider/SettingsProvider';

export default function DashSelection() {

    return (
        <Box sx={{ flexGrow: 1 }} display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100vh'} bgcolor={'background.default'}>
            <Fade in={true} timeout={{ enter: 1000 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} paddingX={20} columns={{ xs: 4, sm: 8, md: 12 }} bgcolor={'background.default'}>
                    {
                        dashs?.map((dash: any) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} >
                                    <Link to={dash.link} target="_blank" style={{ textDecoration: 'none' }}>
                                        <Box paddingY={5} bgcolor={'divider'} borderRadius={4} >
                                            <Typography color={'text.primary'} textAlign={'center'} variant="h4" >
                                                {dash.name}
                                            </Typography>
                                        </Box>
                                    </Link>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Fade>
        </Box>
    )
}