import { Grid } from '@mui/material';
import { defaultDefesaData } from '../fakeData/defesaCivilData';
import DashHeader from '../components/DashHeader';
import CustomCard from '../components/CustomCard';
import CustomCardMulti from '../components/CustomCardMulti';
import VerticalBarChartComponent from '../components/charts/VerticalBarChartComponent';
import MapComponent from '../components/map/MapComponent';
import HorizontalBarChartComponent from '../components/charts/HorizontalBarChartComponent';
import ForecastComponent from '../components/ForecastComponent';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../constants/Link';
import { DefesaCivilType, WeatherDay } from '../types/DefesaCivilType';
import { usePeriodContext } from '../provider/PeriodProvider';
import moment from 'moment';

const weekdays: WeatherDay[] = [
	{
		name: 'SEG',
		icon: 'wb_sunny',
		max: 30,
		min: 13
	}, {
		name: 'TER',
		icon: 'thunderstorm',
		max: 30,
		min: 13
	}, {
		name: 'QUA',
		icon: 'wb_cloudy',
		max: 30,
		min: 13
	}, {
		name: 'QUI',
		icon: 'wb_sunny',
		max: 30,
		min: 13
	}, {
		name: 'SEX',
		icon: 'wb_sunny',
		max: 30,
		min: 13
	}, {
		name: 'SAB',
		icon: 'wb_cloudy',
		max: 30,
		min: 13
	}, {
		name: 'DOM',
		icon: 'wb_cloudy',
		max: 30,
		min: 13
	}
]


export default function DefesaCivilDash() {
	const { start, end } = usePeriodContext();
	const [dash, setDash] = useState<DefesaCivilType>(defaultDefesaData)

	useEffect(() => {
		axios.get(`${ApiUrl}/dashboard/defesa-civil?filter=1${start ? `&start=${moment(start).format('YYYY-MM-DD')}` : ''}${end ? `&end=${moment(end).format('YYYY-MM-DD')}` : ''}`).then((response) => {
			if (response?.data?.success) {
				setDash(response?.data?.data)
			}
		})
	}, [start, end])

	return (
		<Grid container minHeight={'100vh'} overflow={'hidden'} bgcolor={'background.default'}>
			<Grid container item md={12} bgcolor={'background.default'} height={"10vh"}>
				<DashHeader title={dash.title} insights={dash.insights} />
			</Grid>
			<Grid container item xs={6} md={2} borderColor={'divider'} height={"90vh"}>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.qualidade.title ?? "QUALIFICAÇÃO DA DEFESA CIVIL"}
						value={dash.qualidade.value}
						icon={dash.qualidade.icon}
						color={dash.qualidade.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.previsao.title ?? "PREVISÃO DE RISCO PARA O MÊS ATUAL"}
						value={dash.previsao.value}
						icon={dash.previsao.icon}
						color={dash.previsao.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.risco.title ?? "TOTAL DE PESSOAS EM RISCO"}
						value={dash.risco.value}
						icon={dash.risco.icon}
						color={dash.risco.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.emergencias.title ?? "EMERGÊNCIAS ACIONADAS"}
						value={dash.emergencias.value}
						icon={dash.emergencias.icon}
						color={dash.emergencias.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCardMulti
						data={dash.atmosferica}
					/>
				</Grid>
			</Grid>

			<Grid
				container item xs={6} md={10}
				bgcolor="#c9c9c9" borderColor={'divider'}
				display={'flex'} flexDirection={'column'} justifyContent={'space-between'} maxHeight={'90vh'}
			>
				<Grid container item xs={12} md={5} minHeight={'100%'} height={'90vh'}>
					<Grid
						item xs={6} md={12}
						bgcolor={'background.default'} border={2} borderColor={'divider'}
						height={'10vh'}
					>
						<ForecastComponent weekdays={weekdays} />
					</Grid>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'} border={2} borderColor={'divider'}
						height={'20vh'}
					>
						<VerticalBarChartComponent
							data={dash.areas_risco_chart.data}
							title={"TOTAL DE ÁREAS POR NiVEL DE RISCO"}
							withoutLegend
							chartMargin={{
								bottom: 20,
								top: 10,
								left: 20,
								right: 0
							}}
							hideXAxis
						/>
					</Grid>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'} border={2} borderColor={'divider'}
						height={'20vh'}
					>
						<VerticalBarChartComponent
							data={dash.areas_situacao_chart.data}
							title={"TOTAL DE ÁREAS DE RISCO POR SITUAÇÃO"}
							withoutLegend
							chartMargin={{
								bottom: 20,
								top: 10,
								left: 20,
								right: 0
							}}
							hideXAxis
						/>
					</Grid>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'} border={2} borderColor={'divider'}
						height={'40vh'}
					>
						<HorizontalBarChartComponent
							data={dash.nivel_risco_periodo_chart.data}
							title={"NiVEL DE SITUAÇOES DE RISCO COMPARADO O PERiODO ANTERIOR"}
							names={['PERÍODO ANTERIOR', 'PERÍODO ATUAL']}
						/>
					</Grid>
				</Grid>
				<Grid container item xs={12} md={7} minHeight={'90vh'}>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'}
						height={'60%'} border={2} borderColor={'divider'}
					>
						<MapComponent
							textColor={'text.primary'}
							title={'OCORRÊNCIA POR LOCALIDADE'}
							markers={dash.locais.data}
							mapType='terrain'
							zoom={11}
						// withLegend legend='full' legendComponent={
						// 	<Box style={{ height: '100%' }}>
						// 		<Typography textAlign={"center"} fontSize={10} color={'text.primary'}>RISCO DE DESLOCAMENTO DE MASSAS</Typography>
						// 	</Box>
						// }
						/>
					</Grid>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'}
						height={'40%'} border={2} borderColor={'divider'}
					>
						<VerticalBarChartComponent
							data={dash.bairro_risco_chart.data}
							title={"BAIRROS COM MAIORES ÍNDICES DE RISCO"}
							chartMargin={{
								bottom: 20,
								top: 10,
								left: 20,
								right: 0
							}}
							withoutLegend hideXAxis
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
