import { Typography, ButtonGroup, Button, Box } from '@mui/material';
import { usePeriodContext } from '../provider/PeriodProvider';
import React from 'react';
import DashsMenu from './DashsMenu';
import SettingsMenu from './SettingsMenu';

interface DateFilterProps {
}

type Modos = 'day' | 'week' | 'month' | 'year' | 'total' | 'custom'

export default function DateFilter({ ...props }: DateFilterProps) {
    const [mode, setMode] = React.useState<Modos>('total')
    const { onDay, onMonth, onWeek, onTotal, onYear, openCustom } = usePeriodContext()
    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography fontSize={10} color={'text.primary'}>FILTRAR POR PERÍODO</Typography>
                <Box display={'flex'} gap={1}>
                    <DashsMenu />
                    <SettingsMenu />
                </Box>
            </Box>
            <ButtonGroup color='inherit' sx={{ color: 'text.primary' }} variant="text" aria-label="outlined button group" style={{ width: '100%' }}>
                <Button
                    onClick={() => {
                        onDay()
                        setMode('day')
                    }}
                    style={{ padding: '0 2rem' }}
                    sx={{ borderColor: 'grey.100' }}
                    variant={mode === 'day' ? 'contained' : 'text'}
                    color={mode === 'day' ? 'primary' : 'inherit'}
                >
                    DIA
                </Button>
                <Button
                    onClick={() => {
                        onWeek()
                        setMode('week')
                    }}
                    style={{ padding: '0 2rem' }}
                    sx={{ borderColor: 'grey.100' }}
                    variant={mode === 'week' ? 'contained' : 'text'}
                    color={mode === 'week' ? 'primary' : 'inherit'}
                >
                    SEMANA
                </Button>
                <Button
                    onClick={() => {
                        onMonth()
                        setMode('month')
                    }}
                    style={{ padding: '0 2rem' }}
                    sx={{ borderColor: 'grey.100' }}
                    variant={mode === 'month' ? 'contained' : 'text'}
                    color={mode === 'month' ? 'primary' : 'inherit'}
                >
                    MÊS
                </Button>
                <Button
                    onClick={() => {
                        onYear()
                        setMode('year')
                    }}
                    style={{ padding: '0 2rem' }}
                    sx={{ borderColor: 'grey.100' }}
                    variant={mode === 'year' ? 'contained' : 'text'}
                    color={mode === 'year' ? 'primary' : 'inherit'}
                >
                    ANO
                </Button>
                <Button
                    onClick={() => {
                        onTotal()
                        setMode('total')
                    }}
                    style={{ padding: '0 2rem' }}
                    sx={{ borderColor: 'grey.100' }}
                    variant={mode === 'total' ? 'contained' : 'text'}
                    color={mode === 'total' ? 'primary' : 'inherit'}
                >
                    TOTAL
                </Button>
                <Button
                    onClick={() => {
                        openCustom()
                        setMode('custom')
                    }} style={{ width: '100%', maxWidth: '14rem' }} variant='contained' sx={{ color: 'text.primary', fontSize: 12, borderColor: 'grey.100', backgroundColor: 'default' }}>PERSONALIZADO</Button>
            </ButtonGroup>
        </>
    )
}