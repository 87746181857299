export const defaultPopulacaoData = {
	title: "POPULAÇÃO",
	insights: [],
	qualidade: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	indicadores_gerais: [
		{
			title: "POPULAÇÃO DO MUNICÍPIO",
			value: "0",
			icon: "arrow_drop_down",
			color: "warning",
		}, {
			title: "USUÁRIOS DO APP",
			value: "0",
			icon: "arrow_drop_down",
			color: "warning",
		}, {
			title: "ACESSOS TOTAIS NO APP",
			value: "0",
			icon: "arrow_drop_down",
			color: "warning",
		}, {
			title: "NOVOS USUÁRIOS",
			value: "0",
			icon: "arrow_drop_down",
			color: "warning",
		},
	],
	indicadores_emprego: {
		data: [
			{
				title: "USUÁRIOS DESEMPREGADOS",
				value: "0",
				icon: "arrow_drop_down",
				color: "warning",
			}, {
				title: "VAGAS DE EMPREGO DISPONÍVEL",
				value: "0",
				icon: "arrow_drop_down",
				color: "warning",
			}, {
				title: "USUÁRIOS PROCURANDO EMPREGO",
				value: "0",
				icon: "arrow_drop_down",
				color: "warning",
			},
		],
		chart: {
			data: [
				{ name: 'USUÁRIOS DESEMPREGADOS', value: 10 },
				{ name: 'VAGAS DE EMPREGO DISPONÍVEL', value: 10 },
				{ name: 'USUÁRIOS PROCURANDO EMPREGO', value: 10 },
			]
		}
	},
	areas_table: {
		columns: [
			{ name: 'Área', key: 'area' },
			{ name: 'Acessos', key: 'acessos' },
		],
		data: [
			{
				area: 'OUVIDORIA',
				acessos: '0'
			}, {
				area: 'PERFIL DE EMPREGOS',
				acessos: '0'
			}, {
				area: 'SAÚDE',
				acessos: '0'
			}, {
				area: 'EDUCAÇÃO',
				acessos: '0'
			}, {
				area: 'COMÉRCIO LOCAL',
				acessos: '0'
			}
		]
	},
	termos_table: {
		columns: [
			{ name: 'Termo', key: 'termo' },
			{ name: 'Buscas', key: 'buscas' },
		],
		data: [
			{
				termo: 'VACINAÇÃO',
				buscas: '0'
			}, {
				termo: 'MATRÍCULA',
				buscas: '0'
			}, {
				termo: 'DENUNCIAR',
				buscas: '0'
			}, {
				termo: 'POSTO DE SAÚDE',
				buscas: '0'
			}, {
				termo: 'CURSOS',
				buscas: '0'
			}
		]
	},
	locais: {
		data: []
	},
	enquetes_list_data: {
		options: [
			{ name: "Quais serviços mais importantes o App devem contemplar na sua opinião?", id: 1 },
			{ name: "Qual artista deve vir para a festa de aniversário da cidade?", id: 2 },
		]
	},
	gostos_table: {
		columns: [
			{ name: 'Perfil', key: 'perfil' },
			{ name: 'Preenchidos', key: 'preenchidos' },
		],
		data: [
			{
				perfil: 'VAGAS DE EMPREGO',
				preenchidos: '89%'
			}, {
				perfil: 'SAÚDE E QUALIDADE DE VIDA',
				preenchidos: '70%'
			}, {
				perfil: 'EVENTOS, CULTURA E LAZER',
				preenchidos: '10%'
			}, {
				perfil: 'ANIMAIS DE ESTIMAÇÃO',
				preenchidos: '55%'
			}, {
				perfil: 'PERFIL COMPLEMENTAR',
				preenchidos: '20%'
			}
		]
	},
	satisfacao_list_data: {
		title: 'ÁREAS',
		list: [
			{ name: 'ZELADORIA', icon: 'mood', value: '100%', color: '#2fbc30' },
			{ name: 'SAÚDE', icon: 'mood', value: '50%', color: '#2fbc30' },
			{ name: 'EDUCAÇÃO', icon: 'mood', value: '30%', color: '#2fbc30' },
			{ name: 'DEFESA CIVIL', icon: 'mood', value: '50%', color: '#2fbc30' },
			{ name: 'MOBILIDADE', icon: 'sentiment_very_dissatisfied', value: '10%', color: '#2fbc30' },
			{ name: 'ESPORTE, CULTURA E LAZER', icon: 'mood', value: '75%', color: '#2fbc30' },
			{ name: 'ESPORTE, CULTURA E LAZER', icon: 'mood', value: '75%', color: '#2fbc30' },
			{ name: 'ESPORTE, CULTURA E LAZER', icon: 'mood', value: '75%', color: '#2fbc30' },
			{ name: 'ESPORTE, CULTURA E LAZER', icon: 'mood', value: '75%', color: '#2fbc30' },
		]
	},
	satisfacao_saude_list_data: {
		title: 'SAÚDE',
		list: [
			{ name: 'HOSPITAIS PÚBLICOS', icon: 'mood', value: '100%', review: 'Regular', color: '#2fbc30' },
			{ name: 'UNIDADE BÁSICA DE SAÚDE', icon: 'mood', value: '50%', review: 'Bom', color: '#2fbc30' },
			{ name: 'PRONTO ATENDIMENTO', icon: 'mood', value: '30%', review: 'Ruim', color: '#2fbc30' },
			{ name: 'OUTROS', icon: 'mood', value: '50%', review: 'Regular', color: '#2fbc30' },
		]
	},
	satisfacao_hospitais_list_data: {
		title: 'HOSPITAIS PÚBLICOS',
		list: [
			{ name: 'HOSPITAL REGIONAL DE SOROCABA 1', icon: 'mood', value: '100%', review: 'Regular', color: '#2fbc30' },
			{ name: 'HOSPITAL REGIONAL DE SOROCABA 2', icon: 'mood', value: '50%', review: 'Bom', color: '#2fbc30' },
			{ name: 'HOSPITAL REGIONAL DE SOROCABA 3', icon: 'mood', value: '30%', review: 'Ruim', color: '#2fbc30' },
			{ name: 'HOSPITAL REGIONAL DE SOROCABA 4', icon: 'mood', value: '50%', review: 'Regular', color: '#2fbc30' },
			{ name: 'HOSPITAL REGIONAL DE SOROCABA 5', icon: 'mood', value: '30%', review: 'Ruim', color: '#2fbc30' },
			{ name: 'HOSPITAL REGIONAL DE SOROCABA 6', icon: 'mood', value: '50%', review: 'Regular', color: '#2fbc30' },
		]
	},
	satisfacao_hospital_1_list_data: {
		title: 'HOSPITAIS PÚBLICOS',
		list: [
			{
				name: 'Tem acesso ao bebedouro?',
				icon: 'mood',
				value: '100%',
				review: 'Regular',
				color: '#2fbc30',
				chart: {
					data: [
						{ name: 'APP', value: 10 },
						{ name: 'PORTAL WEB', value: 10 },
						{ name: 'PESSOALMENTE', value: 10 },
						{ name: 'OUTROS', value: 20 },
					]
				},
			},
		]
	}
}