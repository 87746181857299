import { GoogleMap, Marker, useJsApiLoader, HeatmapLayer, Polyline, TrafficLayer } from "@react-google-maps/api"
import { ReactNode, useRef, useState } from 'react'
import Icon from '@mui/material/Icon';
import { Box, Typography } from '@mui/material';
import { MarkerType } from "../../types/CommonTypes";
import BusLine from "./BusLine";

const APIkey = 'AIzaSyBz7lfXfUB6zbSLXwq-UX-TipTVMTPwVOA';

const center = {
    lat: -23.48560759790947,
    lng: -47.46842838342718
};

interface MapComponentProps {
    title: string
    textColor: string
    withTraffic?: boolean
    markers?: Array<MarkerType>,
    heatmap?: Array<MarkerType>,
    linhas?: Array<{
        id: string,
        nome: string,
        codigo: string,
    }>
    mapType?: 'roadmap' | 'satellite' | 'hybrid' | 'terrain'
    zoom?: number
    withLegend?: boolean
    legend?: 'full' | 'left'
    legendComponent?: ReactNode
    legendBottom?: number
    containerStyle?: object
    canSwap?: boolean
    handleChangeMapView?: () => void
}

export default function MapComponent({ title, containerStyle = {
    width: '100%',
    height: '96%'
}, withLegend = false, handleChangeMapView, legend = 'full', legendComponent, legendBottom = -6, canSwap = false, textColor, withTraffic = false, markers = [], heatmap = [], linhas = [], mapType = 'roadmap', zoom = 13 }: MapComponentProps) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: APIkey
    })
    const map = useRef<GoogleMap>(null)

    const [shouldPlay, setShouldPlay] = useState(true)

    const handleClickPlay = () => {
        setShouldPlay(!shouldPlay)
    }

    return (
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography textAlign={"left"} fontSize={12} color={textColor}>{title}</Typography>
                {
                    canSwap ?
                        <Box display={'flex'} gap={1}>
                            <Icon title="Mudar visualização dos dados" onClick={handleChangeMapView} sx={{ fontSize: 14, color: 'text.primary', cursor: 'pointer' }} >{'swap_horiz'}</Icon>
                            {
                                shouldPlay ?
                                    <Icon title="Pausar" onClick={handleClickPlay} sx={{ fontSize: 14, color: 'text.primary', cursor: 'pointer' }} >{'pause'}</Icon>
                                    : <Icon title="Continuar" onClick={handleClickPlay} sx={{ fontSize: 14, color: 'text.primary', cursor: 'pointer' }} >{'play_arrow'}</Icon>
                            }
                        </Box>
                        : null
                }

            </Box>
            {
                isLoaded ? <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    mapTypeId={mapType}
                    zoom={zoom}
                    ref={map}
                // tilt={45}
                >
                    {withTraffic ? <TrafficLayer /> : null}
                    {markers?.map((marker, index) => (
                        <Marker
                            key={index}
                            position={{ lat: marker.lat, lng: marker.lng }}
                            icon={{
                                url: require(`../../assets/marcadores/${marker.marker}_small.png`),
                            }}
                            opacity={marker.marker == '0' ? .5 : 1}
                            zIndex={marker.marker == '0' ? -1 : 1}
                        />
                    ))}
                    {heatmap?.length > 0 ? <HeatmapLayer
                        data={heatmap?.map((marker) => new window.google.maps.LatLng(marker.lat, marker.lng))}
                        options={{ radius: 20 }}
                    /> : null}
                    {linhas.length > 0 && !withTraffic ? linhas.map((linha, pos) => <BusLine linha={linha} key={pos} />) : null}
                    { /* Child components, such as markers, info windows, etc. */}
                    <></>
                </GoogleMap > : null
            }
            {
                withLegend ? (legend == 'full' ?
                    <Box position={'absolute'} bottom={-6} width={'100%'} height={'20%'} bgcolor={'background.default'} color={'text.primary'}>
                        {legendComponent}
                    </Box> :
                    <Box position={'absolute'} left={0} bottom={legendBottom} width={'50%'} height={'20%'} bgcolor={'background.default'} color={'text.primary'}>
                        {legendComponent}
                    </Box>) : null
            }
        </div>
    )
}
