import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles'

const ColorLabel = ({ fontFamily, color, label, fontSize, fontWeight, value }: any) => {
    return (
        <Box display="flex" alignItems="center" m={.5}>
            <span style={{
                backgroundColor: color || '#111',
                borderRadius: '50%',
                minWidth: '1rem',
                minHeight: '1rem',
            }}></span>
            <Box ml={2} fontFamily={fontFamily} color={color} fontSize={fontSize || 10} fontWeight={fontWeight || 500}>
                {label} {value ? `(${value})` : null}
            </Box>
        </Box>
    );
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#fff'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, color, fontFamily, index }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text fontFamily={fontFamily} x={x} y={y} fill={color} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

interface PieChartComponentProps {
    title?: string
    data: any
    other?: any
    direction?: any
    radius?: number
    withLegend?: boolean
}

export default function PieChartComponent({ title, data, other = {}, direction = 'row', radius = 60, withLegend = true }: PieChartComponentProps) {
    const theme = useTheme()

    return (
        <>
            <Box display={'flex'} flexDirection={"row"} justifyContent={"space-between"}>
                {title ? <Typography textAlign={"left"} fontSize={10} color={'text.primary'}>{title}</Typography> : null}
                {
                    other.value ?
                        <Typography textAlign={"left"} fontSize={10} color={'text.primary'}>{other.name}: {other.value}</Typography>
                        : null
                }
            </Box>
            {
                data.length > 0 && <Box display={'flex'} height={'100%'} width={'100%'} flexDirection={direction}>
                    <Box height={'100%'} width={'100%'}>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={100} height={100}>
                                <Pie
                                    data={data}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={(props) => renderCustomizedLabel({ ...props, color: 'black', fontFamily: theme.typography.fontFamily })}
                                    outerRadius={radius}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {data.map((entry: any, index: any) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </Box>
                    {

                        withLegend ? <Box display="flex" flexDirection="column" justifyContent={"center"} marginBottom={2}>
                            {data.map((item: any, index: any) => (
                                <ColorLabel fontFamily={theme.typography.fontFamily} key={index} label={item.name} color={COLORS[index % COLORS.length]} value={item.value} />
                            ))}
                        </Box> : null
                    }
                </Box>
            }

        </>
    )
}
