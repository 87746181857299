export const defaultEducacaoData = {
	title: "EDUCAÇÃO",
	insights: [],
	qualidade: {
		value: null,
		icon: "arrow_drop_up",
		color: "success"
	},
	total_alunos: {
		value: null,
		icon: "arrow_drop_up",
		color: "success"
	},
	vagas_disponiveis: {
		value: null,
		icon: "arrow_drop_up",
		color: "success"
	},
	matriculas_pendentes: {
		value: null,
		icon: "arrow_drop_up",
		color: "success"
	},
	ausencia: {
		value: null,
		icon: "arrow_drop_down",
		color: "warning"
	},
	qualidade_merenda: {
		value: null,
		icon: "arrow_drop_up",
		color: "success"
	},
	pontuacao_idbe: {
		value: null,
		icon: "arrow_drop_up",
		color: "success"
	},
	alfabetizacao: {
		value: null,
		icon: "arrow_drop_up",
		color: "success"
	},
	aprovacao: {
		value: null,
		icon: "arrow_drop_up",
		color: "success"
	},
	evasao: {
		value: null,
		icon: "arrow_drop_up",
		color: "success"
	},
	evasao_chart: {
		data: [
			{
				name: "Janeiro",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Fevereiro",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Março",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Abril",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Maio",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Junho",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Julho",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			}
		]
	},
	escolas_vagas_chart: {
		data: []
	},
	escolas_matriculas_chart: {
		data: []
	},
	escolas_aprovacao_chart: {
		data: []
	},
	escolas_reprovacao_chart: {
		data: []
	},
	materias_aprovacao_chart: {
		data: []
	},
	escolas_chart: {
		data: [
			// { name: 'APP', value: 0 },
			// { name: 'PORTAL WEB', value: 0 },
			// { name: 'PESSOALMENTE', value: 0 },
			// { name: 'OUTROS', value: 0 },
		]
	},
	funcionarios_chart: {
		data: [
			// { name: 'APP', value: 0 },
			// { name: 'PORTAL WEB', value: 0 },
			// { name: 'PESSOALMENTE', value: 0 },
			// { name: 'OUTROS', value: 0 },
		]
	},
	locais: {
		data: [],
		data2: [
			{
				"id": 232,
				"nome": "Hospital Regional De Sorocaba II “Adib Domingos Jatene”",
				"nomep": "",
				"rua": "Rodovia Raposo Tavares",
				"descricao": "",
				"complemento": "",
				"numero": "km 106",
				"lat": -23.5268433,
				"lng": -47.5181614,
				"prefix": "",
				"cep": "",
				"bairro": "",
				"visivel": 1,
				"fechado_temporario": 0,
				"fora_cidade": 0,
				"interno": null,
				"minicategoria": null,
				"app_link": null,
				"deleted_at": null,
				"bairro_id": null,
				"sub_categoria_id": 7,
				"laravel_through_key": 2,
				"icone": "far fa-hospital",
				"categoria": 2,
				"marker": "156_amarelo",
				"isOpen": true
			}
		]
	},
}