import { Box, Typography, Skeleton } from '@mui/material';
import Icon from '@mui/material/Icon';

interface CustomCardProps {
    title: string
    value?: string | null
    icon: string
    color: any
}

export default function CustomCard({ title, value, icon, color }: CustomCardProps) {
    return (
        <>
            <Typography textAlign={"center"} fontSize={14} color={'text.primary'} >{title}</Typography>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} maxHeight={'4rem'} height={'100%'} justifyContent={'space-between'} padding={0}>
                {
                    value ? <>
                        <Typography fontSize={18} fontWeight={'bold'} color={'text.primary'}>{value}</Typography>
                        <Icon sx={{ fontSize: 32, margin: 0 }} color={color}>{icon}</Icon>
                    </> : <>
                        <Skeleton sx={{ bgcolor: 'divider', height: '1.5rem', maxHeight: '1.5rem' }} variant="rectangular" width={70} height={'100%'} />
                    </>
                }
            </Box>
        </>
    )
}