import { Box, Grid, Icon, Typography } from '@mui/material';
import { defaultPopulacaoData } from '../fakeData/populacaoData';
import DashHeader from '../components/DashHeader';
import CustomCard from '../components/CustomCard';
import MapComponent from '../components/map/MapComponent';
import PieChartComponent from '../components/charts/PieChartComponent';
import CustomTable from '../components/CustomTable';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../constants/Link';
import { PopulacaoType } from '../types/PopulacaoType';
import { usePeriodContext } from '../provider/PeriodProvider';
import moment from 'moment';
import CustomCardMulti from '../components/CustomCardMulti';
import InteractiveList from '../components/InteractiveList';
import ListComponent from '../components/ListComponent';

export default function PopulacaoDash() {
    const { start, end } = usePeriodContext();
    const [dash, setDash] = useState<PopulacaoType>(defaultPopulacaoData)

    useEffect(() => {
        axios.get(`${ApiUrl}/dashboard/populacao?filter=1${start ? `&start=${moment(start).format('YYYY-MM-DD')}` : ''}${end ? `&end=${moment(end).format('YYYY-MM-DD')}` : ''}`).then((response) => {
            if (response?.data?.success) {
                setDash(response?.data?.data)
            }
        })
    }, [start, end])

    return (
        <Grid container minHeight={'100vh'} overflow={'hidden'} bgcolor={'background.default'}>
            <Grid container item md={12} bgcolor={'background.default'} height={"10vh"}>
                <DashHeader title={dash.title} insights={dash.insights} />
            </Grid>

            <Grid container item xs={6} md={2} borderColor={'divider'} height={"90vh"}>
                <Grid
                    item xs={6} md={12} border={2}
                    display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
                    bgcolor={'background.default'} borderColor={'divider'} padding={1}
                >
                    <CustomCard
                        title={dash.qualidade.title ?? "NÍVEL DE SATISFAÇÃO DA POPULAÇÃO"}
                        value={dash.qualidade.value}
                        icon={dash.qualidade.icon}
                        color={dash.qualidade.color}
                    />
                </Grid>
                <Grid
                    item xs={6} md={12} border={2}
                    display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
                    bgcolor={'background.default'} borderColor={'divider'} padding={1}
                >
                    <CustomCardMulti
                        data={dash.indicadores_gerais}
                    />
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} style={{ gap: '2rem' }}>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                            <Typography textAlign={"center"} fontSize={10} color={'text.primary'}>GÊNERO</Typography>
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} paddingTop={'.5rem'}>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap={'.25rem'}>
                                    <Icon title="Homem" sx={{ fontSize: 20, color: 'text.primary' }} >{'man'}</Icon>
                                    <Typography textAlign={"center"} fontSize={14} color={'text.primary'}>20000</Typography>
                                </Box>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap={'.25rem'}>
                                    <Icon title="Mulher" sx={{ fontSize: 20, color: 'text.primary' }} >{'woman'}</Icon>
                                    <Typography textAlign={"center"} fontSize={14} color={'text.primary'}>20000</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                            <Typography textAlign={"center"} fontSize={10} color={'text.primary'}>DISPOSITIVO</Typography>
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} paddingTop={'.5rem'} >
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap={'.25rem'}>
                                    <Icon title="Android" sx={{ fontSize: 20, color: 'text.primary' }} >{'android'}</Icon>
                                    <Typography textAlign={"center"} fontSize={14} color={'text.primary'}>20000</Typography>
                                </Box>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'} gap={'.25rem'}>
                                    <Icon title="iOS" sx={{ fontSize: 20, color: 'text.primary' }} >{'apple'}</Icon>
                                    <Typography textAlign={"center"} fontSize={14} color={'text.primary'}>20000</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item xs={6} md={12} border={2}
                    display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
                    bgcolor={'background.default'} borderColor={'divider'} padding={1}
                >
                    <PieChartComponent
                        data={dash.indicadores_emprego.chart.data}
                        other={dash.indicadores_emprego.chart.other}
                        //direction={'row'}
                        radius={45} withLegend={false}
                    />
                    <CustomCardMulti
                        data={dash.indicadores_emprego.data}
                    />
                </Grid>
            </Grid>

            <Grid
                container item xs={6} md={10}
                bgcolor="#c9c9c9" borderColor={'divider'}
                display={'flex'} flexDirection={'column'} justifyContent={'space-between'} maxHeight={'90vh'}
            >
                <Grid container item xs={12} md={6} minHeight={'100%'} height={'90vh'}>
                    <Grid
                        item xs={6} md={12} padding={1}
                        bgcolor={'background.default'} border={2} borderColor={'divider'}
                        height={'60vh'}
                    >
                        <MapComponent
                            textColor={'text.primary'}
                            title={'CONCENTRAÇÃO DE USUÁRIOS'}
                            markers={dash.locais.data}
                        // withLegend legend='full'
                        // legendComponent={
                        //     <Box style={{ height: '100%' }}>
                        //         <Typography textAlign={"left"} fontSize={10} color={'text.primary'}>FILTROS</Typography>
                        //         <Box style={{ width: '100%', height: '50%' }}>

                        //         </Box>
                        //     </Box>
                        // }
                        />
                    </Grid>
                    <Grid container item xs={12} md={12} height={'30vh'} >
                        <Grid
                            item xs={6} md={6} padding={1}
                            bgcolor={'background.default'} border={2} borderColor={'divider'}
                            height='100%'
                        >
                            <CustomTable
                                title={"ÁREAS MAIS ACESSADAS NO APP"}
                                data={dash.areas_table.data}
                                columns={dash.areas_table.columns}
                                withColumns={false}
                            />
                        </Grid>
                        <Grid
                            item xs={6} md={6} padding={1}
                            bgcolor={'background.default'} borderLeft={0} borderTop={0}
                            height='100%' border={2} borderColor={'divider'}
                        >
                            <CustomTable
                                title={"TERMOS MAIS BUSCADOS NO APP"}
                                data={dash.termos_table.data}
                                columns={dash.termos_table.columns}
                                withColumns={false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={6} minHeight={'90vh'}>
                    <Grid
                        item xs={6} md={12} padding={1}
                        bgcolor={'background.default'}
                        height={'55%'} maxHeight={'55%'} border={2} borderColor={'divider'}
                    >
                        <InteractiveList title="SATISFAÇÃO DA POPULAÇÃO POR ÁREA" />
                    </Grid>
                    <Grid
                        container item xs={6} md={12}
                        bgcolor={'background.default'}
                        height={'45%'} border={2} borderColor={'divider'}
                    >
                        <Grid item xs={6} md={6} borderRight={2} padding={1} borderColor={'divider'} maxHeight={'85%'}>
                            <ListComponent
                                title='ENQUETES DE PARTICIPAÇÃO POPULAR' withSelect
                                pesquisas={dash.enquetes_list_data.options}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} borderLeft={2} padding={1} borderColor={'divider'}>
                            <CustomTable
                                title={"GOSTOS E INTERESSES DOS USUÁRIOS"}
                                data={dash.gostos_table.data}
                                columns={dash.gostos_table.columns}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};