import React, { useMemo } from 'react';
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

interface SettingsContextType {
    changeMode: (nextMode?: PaletteMode) => void;
    mode: PaletteMode
}

let SettingsContext = React.createContext<SettingsContextType>(null!);


interface SettingsProviderProps {
    children: React.ReactNode
}

export function useSettingsContext() {
    return React.useContext(SettingsContext);
}

export const dashs = [
    {
        name: 'SAÚDE',
        link: '/saude'
    }, {
        name: 'EDUCAÇÃO',
        link: '/educacao'
    }, {
        name: 'ZELADORIA',
        link: '/zeladoria'
    }, {
        name: 'DEFESA CIVIL',
        link: '/defesa-civil'
    }, {
        name: 'SEGURANÇA',
        link: '/seguranca'
    }, {
        name: 'MOBILIDADE',
        link: '/mobilidade'
    }, {
        name: 'POPULAÇÃO',
        link: '/populacao'
    }
]

export default function SettingsProvider({ children, ...props }: SettingsProviderProps) {
    const [mode, setMode] = React.useState<PaletteMode>('dark')

    const value = useMemo(() => ({
        changeMode: (nextMode?: PaletteMode) => {
            setMode(nextMode || (mode === 'light' ? 'dark' : 'light'))
        },
        mode
    }), [mode])

    const getDesignTokens = (mode: PaletteMode) => {
        const commonTokens = {
            typography: {
                fontFamily: [
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    'Roboto',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(','),
            },
            mode,
        };

        if (mode === 'light') {
            return {
                palette: {
                    divider: '#cacaca',
                    background: {
                        default: '#f3f3f3',
                        paper: '#eeecf0',
                    },
                    text: {
                        primary: '#282828',
                        secondary: '#F5EBFF',
                    },
                    ...commonTokens,
                },
            } as ThemeOptions;
        } else {
            return {
                palette: {
                    divider: '#3d3d3d',
                    background: {
                        default: '#282828',
                        paper: '#343434',
                    },
                    text: {
                        primary: '#F5EBFF',
                        secondary: '#282828',
                    },
                    ...commonTokens,
                },
            } as ThemeOptions;
        }
    };

    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    return <SettingsContext.Provider value={value}>
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    </SettingsContext.Provider>
}