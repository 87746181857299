import { HashRouter } from 'react-router-dom'
import { Router } from './Router';
import PeriodProvider from './provider/PeriodProvider';
import SettingsProvider from './provider/SettingsProvider';

export default function App() {
	return (
		<SettingsProvider>
			<PeriodProvider>
				<HashRouter>
					<Router />
				</HashRouter>
			</PeriodProvider>
		</SettingsProvider>
	);
}
