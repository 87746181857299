
import Icon from '@mui/material/Icon';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { dashs } from '../provider/SettingsProvider';
import { useNavigate, useLocation } from 'react-router-dom';

export default function DashsMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const navigate = useNavigate()
    let { pathname } = useLocation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleOpenLink = (link: string) => {
        navigate(link)
        handleClose()
    }

    return (
        <>
            <Icon title="Mudar Dashboard" onClick={handleClick} sx={{ fontSize: 10, color: 'text.primary', cursor: 'pointer' }} >{'monitor'}</Icon>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {
                    dashs?.map((dash: any) => {
                        return <MenuItem selected={dash.link === pathname ? true : false} onClick={() => handleOpenLink(dash.link)}>{dash.name}</MenuItem>
                    })
                }
            </Menu>
        </>
    );
}