import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { SelectItem } from '../types/CommonTypes';
import { SelectChangeEvent } from '@mui/material/Select';
import { usePeriodContext } from '../provider/PeriodProvider';
import { ApiUrl } from '../constants/Link';
import axios from 'axios';
import moment from 'moment';

interface ListComponentProps {
    title: string
    withSelect?: boolean,
    pesquisas?: SelectItem[]
}

export default function ListComponent({ title, withSelect = false, pesquisas = [] }: ListComponentProps) {
    const [options, setOptions] = useState<SelectItem[]>([])
    const [enquete, setEnquete] = useState<any>(0)
    const [list, setList] = useState([])
    const { start, end } = usePeriodContext();

    useEffect(() => {
        setOptions(pesquisas)
        setEnquete(pesquisas[0]?.id)
    }, [pesquisas])

    const handleChangeEnquete = (event: SelectChangeEvent) => {
        setEnquete(event.target.value)
    }
    useEffect(() => {
        if (options.length > 0 && enquete !== 0) {
            axios.get(`${ApiUrl}/dashboard/populacao/pesquisa/${enquete}?filter=1${start ? `&start=${moment(start).format('YYYY-MM-DD')}` : ''}${end ? `&end=${moment(end).format('YYYY-MM-DD')}` : ''}`).then((response) => {
                if (response?.data?.success) {
                    setList(response?.data?.data)
                }
            })
        }
    }, [start, end, enquete, options])

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography textAlign={"left"} fontSize={10} color={'text.primary'}>{title}</Typography>
            </Box>
            {
                withSelect ? <Box sx={{ minWidth: 120, marginTop: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Enquete</InputLabel>
                        <Select
                            style={{ height: '1.75rem' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={enquete}
                            label="Enquete"
                            onChange={handleChangeEnquete}
                        >
                            {
                                options?.map((item: any) => {
                                    return (
                                        <MenuItem selected={item.id === enquete ? true : false} value={item.id}>{item.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box> : null
            }

            <Box overflow={'scroll'} style={{ overflowX: 'hidden' }} height={'90%'} sx={{ width: '100%' }}>
                <nav aria-label="main mailbox folders">
                    <List style={{ width: '100%' }}>
                        {
                            list?.map((item: any, index: number) => {
                                return (
                                    <ListItem style={{ width: '100%' }} >
                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'left'} style={{ width: '100%', height: '100%' }}>
                                            <ListItemText primary={item.name} sx={{ color: 'text.primary', margin: 0 }} />
                                            <Box display={'flex'} margin={0} style={{ backgroundColor: item.color, width: item.value }} paddingBottom={1}> </Box>
                                        </Box>
                                        <Typography textAlign={"left"} alignSelf={'end'} fontSize={20} color={'text.primary'} marginRight={2} marginLeft={2}>{item.value}</Typography>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </nav>
            </Box>
        </>
    );
}