import { Grid } from '@mui/material';
import CustomCard from '../components/CustomCard';
import CustomCardMulti from '../components/CustomCardMulti';
import LineChartComponent from '../components/charts/LineChartComponent';
import VerticalBarChartComponent from '../components/charts/VerticalBarChartComponent';
import MapComponent from '../components/map/MapComponent';
import AgeGenderChartComponent from '../components/charts/AgeGenderChartComponent';
import DashHeader from '../components/DashHeader';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { defaultSaudeData } from '../fakeData/saudeData';
import { ApiUrl } from '../constants/Link';
import { SaudeType } from '../types/SaudeType';
import { usePeriodContext } from '../provider/PeriodProvider';
import moment from 'moment';

export default function SaudeDash() {
	const { start, end } = usePeriodContext();
	const [dash, setDash] = useState<SaudeType>(defaultSaudeData)

	useEffect(() => {
		axios.get(`${ApiUrl}/dashboard/saude?filter=1${start ? `&start=${moment(start).format('YYYY-MM-DD')}` : ''}${end ? `&end=${moment(end).format('YYYY-MM-DD')}` : ''}`).then((response) => {
			if (response?.data?.success) {
				setDash(response?.data?.data)
			}
		})
	}, [start, end])

	return (
		<Grid container minHeight={'100vh'} overflow={'hidden'}>
			<Grid container item md={12} bgcolor={'background.default'} height={"10vh"}>
				<DashHeader title={dash.title} insights={dash.insights} />
			</Grid>

			<Grid container item xs={6} md={2} borderColor={'divider'} height={"90vh"}>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1} minHeight={'4.5rem'}
				>
					<CustomCard
						title="QUALIDADE DE ATENDIMENTO"
						value={dash.atendimento.qualidade.value}
						icon={dash.atendimento.qualidade.icon}
						color={dash.atendimento.qualidade.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCardMulti
						data={dash.total_atendimentos}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1} minHeight={'5.5rem'}
				>
					<CustomCard
						title="TEMPO MÉDIO DE ATENDIMENTO POR UBS"
						value={dash.atendimento.tempo_medio_por_ubs.value}
						icon={dash.atendimento.tempo_medio_por_ubs.icon}
						color={dash.atendimento.tempo_medio_por_ubs.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1} minHeight={'4.5rem'}
				>
					<CustomCard
						title="MÉDIA DE ATENDIMENTO POR UBS"
						value={dash.atendimento.media_por_ubs.value}
						icon={dash.atendimento.media_por_ubs.icon}
						color={dash.atendimento.media_por_ubs.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCardMulti
						data={dash.profissionais_saude}
					/>
				</Grid>
			</Grid>

			<Grid
				container item xs={6} md={10}
				bgcolor="#c9c9c9" borderColor={'divider'}
				display={'flex'} flexDirection={'column'} justifyContent={'space-between'} maxHeight={'90vh'}
			>
				<Grid container item xs={12} md={6} minHeight={'100%'} height={'90vh'}>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'} border={2} borderColor={'divider'}
						maxHeight={'30vh'}
					>
						<LineChartComponent
							data={dash.atendimento_tempo_chart.data}
							title={"ATENDIMENTOS x TEMPO"}
							chartMargin={{
								top: 15,
								right: 30,
								left: 0,
								bottom: 10
							}}
						/>
					</Grid>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'} border={2} borderColor={'divider'}
						maxHeight={'30vh'}
					>
						<VerticalBarChartComponent
							data={dash.ubs_tempo_chart.data}
							title={"UBS COM MAIOR TEMPO DE ATENDIMENTO"}
							chartMargin={{
								top: 5,
								right: 0,
								left: 20,
								bottom: 15
							}} withoutLegend hideXAxis
						/>
					</Grid>
					<Grid container item xs={12} md={12} maxHeight={'30vh'}>
						<Grid
							item xs={6} md={7} padding={1}
							bgcolor={'background.default'} border={2} borderColor={'divider'}
							height='100%'
						>
							<VerticalBarChartComponent
								data={dash.medicamentos_chart.data}
								title={"MEDICAMENTOS SOLICITADOS"}
								chartMargin={{
									top: 5,
									right: 0,
									left: 20,
									bottom: 15
								}} withoutLegend hideXAxis
							/>
						</Grid>
						<Grid
							item xs={6} md={5} padding={1}
							bgcolor={'background.default'} borderLeft={0} borderTop={0}
							height='100%' border={2} borderColor={'divider'}
						>
							<AgeGenderChartComponent
								data1={dash.idade_genero_chart.homem_data}
								data2={dash.idade_genero_chart.mulher_data}
								label1={"HOMENS"}
								label2={"MULHERES"}
								title={"FAIXA ETÁRIA DOS PACIENTES"}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid container item xs={12} md={6} minHeight={'90vh'}>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'}
						height={'50%'} border={2} borderColor={'divider'}
					>
						<MapComponent
							textColor={'text.primary'}
							title={'MAPA DE QUALIDADE DAS UBSs E HOSPITAIS'}
							markers={dash.locais.data}
						// withLegend legend='left' legendComponent={
						// 	<Box style={{ height: '100%' }}>
						// 		<Typography textAlign={"center"} fontSize={10} color={'text.primary'}>NÍVEL DE QUALIDADE</Typography>
						// 		<Box style={{ width: '100%', height: '50%' }}>

						// 		</Box>
						// 	</Box>
						// }
						/>
					</Grid>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'}
						height={'50%'} border={2} borderColor={'divider'}
					>
						<VerticalBarChartComponent
							data={dash.motivo_atendimento_chart.data}
							title={"MOTIVO DO ATENDIMENTO"}
							chartMargin={{
								top: 5,
								right: 0,
								left: 20,
								bottom: 15
							}} withoutLegend hideXAxis
						/>
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	)
}
