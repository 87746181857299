import { Grid, Typography } from '@mui/material';
import Insights from '../components/Insights';
import DateFilter from '../components/DateFilter';

interface DashHeaderProps {
    title: string
    insights: string[]
}

export default function DashHeader({ title, insights }: DashHeaderProps) {
    return (
        <>
            <Grid
                item xs={6} md={2} bgcolor={'background.default'}
                border={2} borderColor={'divider'} display={"flex"} alignItems={"center"} justifyContent={"center"}
            >
                <Typography fontSize={24} fontWeight={"bold"} color={'text.primary'}>
                    {title}
                </Typography>
            </Grid>
            <Grid
                item xs={6} md={6} padding={1} border={2}
                bgcolor={'background.default'} borderColor={'divider'}
                display={"flex"} flexDirection={"column"} alignItems={"flex-start"} justifyContent={"space-around"}
            >
                <Insights
                    content={insights}
                />
            </Grid>
            <Grid
                item xs={6} md={4} padding={1} border={2}
                bgcolor={'background.default'} borderColor={'divider'}
                display={"flex"} flexDirection={"column"} alignItems={"flex-start"} justifyContent={"space-around"}
            >
                <DateFilter />
            </Grid>
        </>
    )
}