import { Box, Typography, Icon } from '@mui/material';
import { WeatherDay } from '../types/DefesaCivilType';

interface ForecastComponentProps {
    weekdays?: WeatherDay[]
}

export default function ForecastComponent({ weekdays = [] }: ForecastComponentProps) {
    return (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} width={'100%'} height={'100%'}>
            {
                weekdays?.map((day: any, index: number) => {
                    return (
                        <Box display={'flex'} flexDirection={'column'} bgcolor={'background.default'}
                            borderRight={index == weekdays.length - 1 ? 0 : 2}
                            borderLeft={index == 0 ? 0 : 2}
                            width={'6rem'} borderColor={'divider'} alignItems={'center'} justifyContent={'space-around'} height={'100%'}>
                            <Typography color={'text.primary'} textAlign={'center'} fontSize={10}>
                                {day.name}
                            </Typography>
                            <Icon sx={{ color: 'text.primary' }} >{day.icon}</Icon>
                            <Typography color={'text.primary'} textAlign={'center'} fontSize={10} fontWeight={'bold'}>
                                <span style={{ color: '#f16a10' }}>{day.max}°C</span>
                                <span style={{ color: '#919191' }}>&nbsp;/&nbsp;</span>
                                <span style={{ color: '#1da0e7' }}>{day.min}°C</span>
                            </Typography>
                        </Box>
                    )
                })
            }
        </Box>
    )
}