import { Box, Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import { Counter } from '../types/CommonTypes';
interface CustomCardMultiProps {
    data: Array<Counter>,
}

type Colors = "disabled" | "action" | "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning"

export default function CustomCardMulti({ data }: CustomCardMultiProps) {
    return (
        <>
            {
                data?.map((item) => {
                    return (
                        <>
                            <Typography textAlign={"center"} fontSize={10} color={'text.primary'}>{item.title}</Typography>
                            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography fontSize={18} fontWeight={'bold'} color={'text.primary'}>{item.value}</Typography>
                                {
                                    item.icon ? <Icon sx={{ fontSize: 32, margin: 0 }} color={item.color as Colors}>{item.icon}</Icon> : null
                                }
                            </Box>
                        </>
                    )
                })
            }
        </>
    )
}