
import Icon from '@mui/material/Icon';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Box, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useSettingsContext } from '../provider/SettingsProvider';

export default function SettingsMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const { changeMode, mode } = useSettingsContext()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Icon title="Configurações" onClick={handleClick} sx={{ fontSize: 10, color: 'text.primary', cursor: 'pointer' }} >{'settings'}</Icon>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => changeMode()}>
                    {mode === 'light' ?
                        <Box display={'flex'} gap={1}>
                            <Icon sx={{ color: 'text.primary' }} >{'dark_mode'}</Icon>
                            <Typography color={'text.primary'}>Dark</Typography>
                        </Box> : <Box display={'flex'} gap={1}>
                            <Icon sx={{ color: 'text.primary' }} >{'light_mode'}</Icon>
                            <Typography color={'text.primary'}>Light</Typography>
                        </Box>}
                </MenuItem>
            </Menu>
        </>
    );
}