import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import React, { useMemo } from 'react';

interface PeriodContextType {
    start: Date | null;
    end: Date | null;
    onDay: () => void;
    onWeek: () => void;
    onMonth: () => void;
    onYear: () => void;
    onTotal: () => void;
    openCustom: () => void;
    closeCustom: () => void;
}

let PeriodContext = React.createContext<PeriodContextType>(null!);

interface PeriodProviderProps {
    children: React.ReactNode
}

export function usePeriodContext() {
    return React.useContext(PeriodContext);
}

export default function PeriodProvider({ children, ...props }: PeriodProviderProps) {
    const [start, setStart] = React.useState<Date | null>(null);
    const [end, setEnd] = React.useState<Date | null>(null);
    const [open, setOpen] = React.useState(false);

    const value = useMemo(() => ({
        start, end,
        onDay: () => {
            setStart(new Date());
            setEnd(new Date());
        },
        onWeek: () => {
            let today = new Date();
            let first = today.getDate() - today.getDay();
            let last = first + 6;
            setStart(new Date(today.setDate(first)));
            setEnd(new Date(today.setDate(last)));
        },
        onMonth: () => {
            let today = new Date();
            let first = new Date(today.getFullYear(), today.getMonth(), 1);
            let last = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            setStart(first);
            setEnd(last);
        },
        onYear: () => {
            let today = new Date();
            let first = new Date(today.getFullYear(), 0, 1);
            let last = new Date(today.getFullYear(), 11, 31);
            setStart(first);
            setEnd(last);
        },
        onTotal: () => {
            setStart(null);
            setEnd(null);
        },
        openCustom: () => setOpen(true),
        closeCustom: () => setOpen(false),
    }), [start, end])

    return <PeriodContext.Provider value={value}>
        {children}
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
            <DialogTitle>
                Selecione um período
            </DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container mt={3} spacing={3} justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                        <Grid item xs={12} md={5}>
                            <DatePicker
                                label="Começo"
                                value={moment(start)}
                                onChange={(newValue) => {
                                    if (end && moment(newValue).isAfter(end, 'day')) {
                                        setEnd(moment(newValue).toDate());
                                    }
                                    setStart(moment(newValue).toDate());
                                }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                    }
                                }}
                            />
                            <FormControlLabel
                                control={<Checkbox size="small" checked={start === null} />} label="Desde o Começo"
                                onChange={() => setStart(null)}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            Até
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <DatePicker
                                label="Fim"
                                value={moment(end)}
                                onChange={(newValue) => {
                                    setEnd(moment(newValue).toDate());
                                }}
                                minDate={start ? moment(start) : null}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                    }
                                }}
                            />
                            <FormControlLabel
                                control={<Checkbox size="small" checked={end === null} />} label="Até Hoje"
                                onChange={() => setEnd(null)}
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    </PeriodContext.Provider>;
}