import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts";
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles'
interface MarginProps {
    top: number
    right: number
    left: number
    bottom: number
}

interface LineChartComponentProps {
    data: any
    title: string
    withoutLegend?: boolean
    chartMargin?: MarginProps
}

export default function LineChartComponent({ data, title, withoutLegend = false, chartMargin = {
    top: 30,
    right: 30,
    left: 20,
    bottom: 30
} }: LineChartComponentProps) {
    const theme = useTheme()

    return (
        <>
            <Typography textAlign={"left"} fontSize={10} color={'text.primary'}>{title}</Typography>
            <ResponsiveContainer width="100%" height="100%" >
                <LineChart width={500} height={300} data={data} margin={chartMargin} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" tick={{ width: 500, fontSize: 12, fontFamily: theme.typography.fontFamily, fill: theme.palette.text.primary }} />
                    <YAxis tick={{ width: 500, fontSize: 12, fontFamily: theme.typography.fontFamily, fill: theme.palette.text.primary }} />
                    <Tooltip />
                    {withoutLegend ? null : <Legend />}
                    <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{
                        r: 8
                    }} />
                    <Line type="monotone" dataKey="agendamentos" stroke="#82ca9d" />
                    <Line type="monotone" dataKey="expontaneos" stroke="#da4f1c" />
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}