import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Icon, Typography, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, useTheme } from '@mui/material';
import { InteractiveListItem, SelectItem } from '../types/CommonTypes';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { usePeriodContext } from '../provider/PeriodProvider';
import { ApiUrl } from '../constants/Link';
import axios from 'axios';
import moment from 'moment';
import VerticalBarChartComponent from './charts/VerticalBarChartComponent';

interface InteractiveListProps {
    title: string
    withBreadcrumbs?: boolean
    withSelect?: boolean
    withIcons?: boolean
    withMenu?: boolean
}

interface ListData {
    title: string
    list: InteractiveListItem[]
    select?: SelectItem[]
}

export default function InteractiveList({ title, withMenu = false }: InteractiveListProps) {
    const { start, end } = usePeriodContext();
    const [allData, setAllData] = useState<ListData[]>([])
    const theme = useTheme()

    const maxDeep = 4

    const handleClickItem = (deep: number, id: number) => {
        let url = '';

        if (deep === 4) {
            return
        }

        switch (deep) {
            case 1:
                url = `/cat/${id}`
                break
            case 2:
                url = `/sub/${id}`
                break
            case 3:
                url = `/local/${id}`
                break
            default:
                url = ''
        }

        // make api request
        axios.get(`${ApiUrl}/dashboard/populacao/avaliacao${url}?filter=1${start ? `&start=${moment(start).format('YYYY-MM-DD')}` : ''}${end ? `&end=${moment(end).format('YYYY-MM-DD')}` : ''}`).then((response) => {
            if (response?.data?.success) {
                let newData = response.data.data;
                setAllData([...allData, newData])
            }
        })
    }

    useEffect(() => {
        handleClickItem(0, 0)
    }, [])

    const handleClickBreadcrumb = (index: number) => {
        const updatedData = allData.slice(0, index + 1);
        setAllData(updatedData);
    }

    const handleClickMenu = () => {

    }

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography textAlign={"left"} fontSize={10} color={'text.primary'}>{title}</Typography>
                {withMenu ? <Box display={'flex'} gap={1}>
                    <Icon title="Mudar Visualização" onClick={handleClickMenu} sx={{ fontSize: 10, color: 'text.primary', cursor: 'pointer' }} >{'menu'}</Icon>
                </Box> : null}
            </Box>
            <Breadcrumbs
                style={{ minHeight: '1.5rem', display: 'flex', alignItems: 'center' }}
                separator={<NavigateNextIcon fontSize="small" style={{ color: theme.palette.text.primary }} />}
                aria-label="breadcrumb"
            >
                {allData?.map((breadcrumb, index) => {
                    return (
                        <Typography style={{ cursor: 'pointer' }} key={index + 1} fontSize={10} color="text.primary" onClick={() => handleClickBreadcrumb(index)}>
                            {breadcrumb.title}
                        </Typography>
                    )
                })}
            </Breadcrumbs>

            <Box overflow={'scroll'} style={{ overflowX: 'hidden' }} height={'90%'} sx={{ width: '100%' }}>
                <nav aria-label="main mailbox folders">
                    <List style={{ width: '100%' }}>
                        {
                            allData[allData.length - 1]?.list?.map((item: any, index: number) => {
                                if (allData.length < maxDeep) {
                                    return (
                                        <ListItem style={{ width: '100%' }} disablePadding onClick={() => handleClickItem(allData.length, item.id)}>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <Icon sx={{ marginLeft: 0, color: item.color }}>{item.icon}</Icon>
                                                </ListItemIcon>
                                                <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'left'} style={{ width: '100%', height: '100%' }}>
                                                    <ListItemText primary={item.name} sx={{ color: 'text.primary', margin: 0 }} />
                                                    <Box display={'flex'} margin={0} style={{ backgroundColor: item.color, width: item.value }} paddingBottom={1}> </Box>
                                                </Box>
                                                <Typography textAlign={"left"} alignSelf={'end'} fontSize={20} color={'text.primary'} marginRight={2} marginLeft={2}>{item.display ?? item.value}</Typography>
                                                <ChevronRight sx={{ color: 'text.primary' }} />
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                } else if (item.graphs) {
                                    return <>
                                        <ListItem style={{ width: '100%' }} disablePadding>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <Icon sx={{ marginLeft: 0, color: item.color }}>{item.icon}</Icon>
                                                </ListItemIcon>
                                                <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'left'} style={{ width: '100%', height: '100%' }}>
                                                    <ListItemText primary={item.name} sx={{ color: 'text.primary', margin: 0 }} />
                                                    <Box display={'flex'} margin={0} style={{ backgroundColor: item.color, width: item.value }} paddingBottom={1}> </Box>
                                                </Box>
                                                <Typography textAlign={"left"} alignSelf={'end'} fontSize={20} color={'text.primary'} marginRight={2} marginLeft={2}>{item.display ?? item.value}</Typography>
                                            </ListItemButton>
                                        </ListItem>
                                        {item.graphs.map((graph: any) => <Accordion
                                            key={index}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>{graph.pergunta}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails >
                                                <Box height={'12rem'}>
                                                    <VerticalBarChartComponent
                                                        data={graph.opcaos} title={graph.pergunta}
                                                        barKey='respostas_count' categoryKey='opcao'
                                                        barName='Votos'
                                                    />
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>)}
                                    </>
                                }
                                return null;
                            })
                        }
                    </List>
                </nav>
            </Box>
        </>
    );
}