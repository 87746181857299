import { Typography, Skeleton, Box, Slide, SlideProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
interface InsightsProps {
    content?: string[]
}

export default function Insights({ content }: InsightsProps) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState<SlideProps['direction']>('left');
    const [slideIn, setSlideIn] = useState<SlideProps['in']>(true);

    const containerRef = useRef(null);

    const time = 6000;

    useEffect(() => {
        if (content) {
            const timeout = setInterval(() => {
                if (!slideIn) {
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
                }
                setSlideIn(!slideIn)
                direction == 'left' ? setDirection('right') : setDirection('left')
            }, slideIn ? time : time / 2);
            return () => clearInterval(timeout);
        }
    }, [currentIndex, slideIn, direction]);

    return (
        <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'space-around'} width={'100%'} ref={containerRef} sx={{ overflow: 'hidden' }}>
            {
                content && content.length > 0 ?
                    <>
                        <Typography color={'text.primary'}>INSIGHTS</Typography>
                        <Typography
                            color={'text.primary'} bgcolor={'divider'} width={"100%"} display={"flex"} flexWrap={"wrap"} paddingLeft={'.25rem'}
                        >
                            <Slide
                                in={slideIn} direction={direction}
                                timeout={{ appear: time / 3, enter: time / 3, exit: time / 3 }}
                                container={containerRef.current}
                            >
                                <span>{content[currentIndex]}</span>
                            </Slide>
                        </Typography>
                    </> : <>
                        <Typography color={'text.primary'}>INSIGHTS</Typography>
                        <Skeleton sx={{ bgcolor: 'divider', height: '1.5rem' }} variant="rectangular" width={"100%"} />
                    </>
            }
        </Box >
    )
}