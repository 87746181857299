import { Marker, Polyline } from '@react-google-maps/api';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ApiUrl } from '../../constants/Link';
import BusIcon from '../../assets/marcadores/transporte_onibus_small.png'

interface BusLineProps {
    linha: {
        id: string,
        nome: string,
        codigo: string,
        color?: string,
    }
}

export default function BusLine({ linha, ...props }: BusLineProps) {
    const [caminho, setCaminho] = useState<Array<{ lat: number, lng: number }>>([])
    const [point, setPoint] = useState<{ lat: number, lng: number }>({ lat: 0, lng: 0 })
    const [mainLoad, setMainLoad] = useState(false)

    useEffect(() => {
        if (mainLoad) {
            const timeout = setInterval(() => {
                axios.get(`${ApiUrl}/dashboard/transporte/linha/driving/${linha.id}?pointOnly=true`).then((r) => {
                    if (r.data.success) {
                        setPoint(r.data.ponto)
                    }
                }, () => { })
            }, 5000)
            return () => clearInterval(timeout)
        }
        else {
            axios.get(`${ApiUrl}/dashboard/transporte/linha/driving/${linha.id}`).then((r) => {
                if (r.data.success) {
                    setPoint(r.data.ponto)
                    setCaminho(r.data.path)
                    setMainLoad(true)
                }
            }, () => { })
        }
    }, [linha, mainLoad])

    return <>
        <Marker
            position={{ lat: point?.lat, lng: point?.lng }}
            icon={{
                url: BusIcon,
            }}
        />
        <Polyline
            path={caminho}
            options={{
                strokeColor: linha.color ?? '#000000',
            }}
        />
    </>
}