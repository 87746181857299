export const defaultZeladoriaData = {
	title: "ZELADORIA",
	insights: [],
	satisfacao: {
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	ocorrencias: [
		{
			title: "TOTAL DE OCORRÊNCIAS",
			value: "0",
			icon: "arrow_drop_down",
			color: "warning",
		}, {
			title: "TOTAL PENDENTES",
			value: "0",
			icon: "",
			color: "",
		}, {
			title: "TOTAL RESOLVIDAS",
			value: "0",
			icon: "",
			color: "",
		}, {
			title: "TOTAL EXPIRADAS",
			value: "0",
			icon: "",
			color: "",
		},
	],
	novas_ocorrencias: {
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	ocorrencias_media: {
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	prazo_resposta: {
		value: "0 DIAS",
		icon: "arrow_drop_down",
		color: "warning"
	},
	atendimento_tempo_chart: {
		data: [
			{
				name: 'Janeiro',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Fevereiro',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Março',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Abril',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Maio',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Junho',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Julho',
				uv: 0,
				pv: 0,
				amt: 0,
			},
		]
	},
	manifestacoes_assunto_chart: {
		data: []
	},
	manifestacoes_secretaria_chart: {
		data: []
	},
	bairros_manifestacao_chart: {
		data: []
	},
	entrada_ocorrencias_chart: {
		data: [
			// { name: 'APP', value: 0 },
			// { name: 'PORTAL WEB', value: 0 },
			// { name: 'PESSOALMENTE', value: 0 },
			// { name: 'SISTEMA', value: 0 },
			// { name: 'OUTROS', value: 0 },
		]
	},
	gravidade_ocorrencias_chart: {
		data: [
			// { name: 'ALTA', value: 0 },
			// { name: 'MÉDIA', value: 0 },
			// { name: 'BAIXA', value: 0 },
		]
	},
	locais: {
		data: []
	},
}