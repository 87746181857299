export const defaultDefesaData = {
	title: "DEFESA CIVIL",
	insights: [],
	qualidade: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	previsao: {
		title: null,
		value: "...",
		icon: "arrow_drop_up",
		color: "success"
	},
	risco: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	emergencias: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	atmosferica: [
		{
			title: "TEMPERATURA",
			value: "25",
			icon: "arrow_drop_down",
			color: "warning",
		}, {
			title: "VELOCIDADE DO VENTO",
			value: "15",
			icon: "",
			color: "",
		}, {
			title: "PLUVIOMETRIA",
			value: "0",
			icon: "",
			color: "",
		}, {
			title: "UMIDADE RELATIVA DO AR",
			value: "50",
			icon: "",
			color: "",
		},
	],
	nivel_risco_periodo_chart: {
		data: [
			{
				name: 'Janeiro',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Fevereiro',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Março',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Abril',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Maio',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Junho',
				uv: 0,
				pv: 0,
				amt: 0,
			},
			{
				name: 'Julho',
				uv: 0,
				pv: 0,
				amt: 0,
			},
		]
	},
	areas_risco_chart: {
		data: []
	},
	areas_situacao_chart: {
		data: []
	},
	bairro_risco_chart: {
		data: []
	},
	locais: {
		data: []
	},
	weekdays: [
		{
			name: 'SEG',
			icon: 'wb_sunny',
			max: 30,
			min: 13
		}, {
			name: 'TER',
			icon: 'thunderstorm',
			max: 30,
			min: 13
		}, {
			name: 'QUA',
			icon: 'wb_cloudy',
			max: 30,
			min: 13
		}, {
			name: 'QUI',
			icon: 'wb_sunny',
			max: 30,
			min: 13
		}, {
			name: 'SEX',
			icon: 'wb_sunny',
			max: 30,
			min: 13
		}, {
			name: 'SAB',
			icon: 'wb_cloudy',
			max: 30,
			min: 13
		}, {
			name: 'DOM',
			icon: 'wb_cloudy',
			max: 30,
			min: 13
		}
	]
}