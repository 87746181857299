import { Grid } from '@mui/material';
import { defaultMobilidadeData } from '../fakeData/mobilidadeData';
import DashHeader from '../components/DashHeader';
import CustomCard from '../components/CustomCard';
import MapComponent from '../components/map/MapComponent';
import VerticalBarChartComponent from '../components/charts/VerticalBarChartComponent';
import PieChartComponent from '../components/charts/PieChartComponent';
import CustomTable from '../components/CustomTable';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../constants/Link';
import { MobilidadeType } from '../types/MobilidadeType';
import { usePeriodContext } from '../provider/PeriodProvider';
import moment from 'moment';

export default function MobilidadeDash() {
	const { start, end } = usePeriodContext();
	const [dash, setDash] = useState<MobilidadeType>(defaultMobilidadeData)
	const [showTraffic, setShowTraffic] = useState(false)

	const intervalTime = 20000;

	useEffect(() => {
		axios.get(`${ApiUrl}/dashboard/mobilidade?filter=1${start ? `&start=${moment(start).format('YYYY-MM-DD')}` : ''}${end ? `&end=${moment(end).format('YYYY-MM-DD')}` : ''}`).then((response) => {
			if (response?.data?.success) {
				setDash(response?.data?.data)
			}
		})
	}, [start, end])

	useEffect(() => {
		const toggleShowTraffic = () => {
			setShowTraffic((prevState) => !prevState);
		};
		const intervalId = setInterval(toggleShowTraffic, intervalTime);
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return (
		<Grid container minHeight={'100vh'} overflow={'hidden'} bgcolor={'background.default'}>
			<Grid container item md={12} bgcolor={'background.default'} height={"10vh"}>
				<DashHeader title={dash.title} insights={dash.insights} />
			</Grid>

			<Grid container item xs={6} md={2} borderColor={'divider'} height={"90vh"}>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.qualidade.title ?? "QUALIDADE DO TRANSPORTE PÚBLICO"}
						value={dash.qualidade.value}
						icon={dash.qualidade.icon}
						color={dash.qualidade.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.media_passageiros.title ?? "MÉDIA DE PASSAGEIROS"}
						value={dash.media_passageiros.value}
						icon={dash.media_passageiros.icon}
						color={dash.media_passageiros.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.tempo_transportes.title ?? "TEMPO MÉDIO ENTRE TRANSPORTES"}
						value={dash.tempo_transportes.value}
						icon={dash.tempo_transportes.icon}
						color={dash.tempo_transportes.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.tempo_atraso.title ?? "TEMPO MÉDIO DE ATRASO"}
						value={dash.tempo_atraso.value}
						icon={dash.tempo_atraso.icon}
						color={dash.tempo_atraso.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.total_veiculos.title ?? "TOTAL DE VEÍCULOS"}
						value={dash.total_veiculos.value}
						icon={dash.total_veiculos.icon}
						color={dash.total_veiculos.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.acidentes.title ?? "ACIDENTES DURANTE VIAGEM"}
						value={dash.acidentes.value}
						icon={dash.acidentes.icon}
						color={dash.acidentes.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.valor_passagem.title ?? "VALOR DA PASSAGEM DE ÔNIBUS"}
						value={dash.valor_passagem.value}
						icon={dash.valor_passagem.icon}
						color={dash.valor_passagem.color}
					/>
				</Grid>
			</Grid>

			<Grid
				container item xs={6} md={10}
				bgcolor="#c9c9c9" borderColor={'divider'}
				display={'flex'} flexDirection={'column'} justifyContent={'space-between'} maxHeight={'90vh'}
			>
				<Grid
					container item xs={6} md={12} maxHeight={'60vh'}
					bgcolor={'background.default'} borderColor={'divider'}
				>
					<Grid
						item xs={6} md={8} border={2}
						display={'flex'} flexDirection={'column'}
						bgcolor={'background.default'} borderColor={'divider'} padding={1}
					>
						<MapComponent
							textColor={'text.primary'}
							title={!showTraffic ? 'MAPA COM LINHAS DE ÔNIBUS' : 'MAPA DE TRÁFEGO URBANO'}
							linhas={dash.locais.linhas}
							withTraffic={showTraffic}
							canSwap
						// withLegend legend='left' legendBottom={-1}
						// legendComponent={
						// 	!showTraffic ?
						// 		<Box style={{ height: '100%' }}>
						// 			<Typography textAlign={"center"} fontSize={12} color={'text.primary'}>TIPO DE TRANSPORTE</Typography>
						// 		</Box> : <Box style={{ height: '100%' }}>
						// 			<Typography textAlign={"center"} fontSize={12} color={'text.primary'}>NÍVEL DE CONGESTIONAMENTO</Typography>
						// 		</Box>
						// }
						/>
					</Grid>
					<Grid
						item xs={6} md={4} border={2}
						display={'flex'} flexDirection={'column'}
						bgcolor={'background.default'} borderColor={'divider'} padding={1}
					>
						<CustomTable
							data={dash.linhas_table.data}
							columns={dash.linhas_table.columns}
							title={"LINHAS DE ÔNIBUS EM CIRCULAÇÃO"}
							withColor
						/>
					</Grid>
				</Grid>
				<Grid
					container item xs={6} md={12} maxHeight={'30vh'}

					bgcolor={'background.default'} borderColor={'divider'}
				>
					<Grid
						container item xs={6} md={7} border={2} maxHeight={'30vh'}
						// display={'flex'} flexDirection={'column'}
						bgcolor={'background.default'} borderColor={'divider'} padding={1}
					>
						<Grid item md={6} height={'25vh'}>
							<PieChartComponent
								title={"TRANSPORTES PÚBLICOS DISPONÍVEIS"}
								data={dash.transportes_disponiveis_chart.data}
								other={dash.transportes_disponiveis_chart.other}
								//direction={'row'}
								radius={60}
							/>
						</Grid>
						<Grid item md={6} height={'25vh'}>
							<CustomTable
								data={dash.transportes_disponiveis_table.data}
								columns={dash.transportes_disponiveis_table.columns}
							/>
						</Grid>
					</Grid>
					<Grid
						item xs={6} md={5} border={2}
						display={'flex'} flexDirection={'column'}
						bgcolor={'background.default'} borderColor={'divider'} padding={1}
					>
						<VerticalBarChartComponent
							data={dash.media_uso_chart.data}
							title={"MÉDIA DE USO POR DIA DA SEMANA"}
							chartMargin={{
								bottom: 0,
								top: 10,
								left: 20,
								right: 0
							}}
							withoutLegend hideXAxis
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
