import { Grid } from '@mui/material';
import { defaultZeladoriaData } from '../fakeData/zeladoriaData';
import DashHeader from '../components/DashHeader';
import CustomCard from '../components/CustomCard';
import CustomCardMulti from '../components/CustomCardMulti';
import VerticalBarChartComponent from '../components/charts/VerticalBarChartComponent';
import MapComponent from '../components/map/MapComponent';
import HorizontalBarChartComponent from '../components/charts/HorizontalBarChartComponent';
import PieChartComponent from '../components/charts/PieChartComponent';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../constants/Link';
import { ZeladoriaType } from '../types/ZeladoriaType';
import { usePeriodContext } from '../provider/PeriodProvider';
import moment from 'moment';

export default function ZeladoriaDash() {
	const { start, end } = usePeriodContext();
	const [dash, setDash] = useState<ZeladoriaType>(defaultZeladoriaData)

	useEffect(() => {
		axios.get(`${ApiUrl}/dashboard/zeladoria?filter=1${start ? `&start=${moment(start).format('YYYY-MM-DD')}` : ''}${end ? `&end=${moment(end).format('YYYY-MM-DD')}` : ''}`).then((response) => {
			if (response?.data?.success) {
				setDash(response?.data?.data)
			}
		})
	}, [start, end])

	return (
		<Grid container minHeight={'100vh'} overflow={'hidden'} bgcolor={'background.default'}>
			<Grid container item md={12} bgcolor={'background.default'} height={"10vh"}>
				<DashHeader title={dash.title} insights={dash.insights} />
			</Grid>

			<Grid container item xs={6} md={2} borderColor={'divider'} height={"90vh"}>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title="NÍVEL DE SATISFAÇÃO"
						value={dash.satisfacao.value}
						icon={dash.satisfacao.icon}
						color={dash.satisfacao.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCardMulti
						data={dash.ocorrencias}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title="NOVAS OCORRÊNCIAS"
						value={dash.novas_ocorrencias.value}
						icon={dash.novas_ocorrencias.icon}
						color={dash.novas_ocorrencias.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title="MÉDIA DE OCORRÊNCIAS POR MÊS"
						value={dash.ocorrencias_media.value}
						icon={dash.ocorrencias_media.icon}
						color={dash.ocorrencias_media.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title="PRAZO MÉDIO DE RESPOSTA"
						value={dash.prazo_resposta.value}
						icon={dash.prazo_resposta.icon}
						color={dash.prazo_resposta.color}
					/>
				</Grid>
			</Grid>

			<Grid
				container item xs={6} md={10}
				bgcolor="#c9c9c9" borderColor={'divider'}
				display={'flex'} flexDirection={'column'} justifyContent={'space-between'} maxHeight={'90vh'}
			>
				<Grid container item xs={12} md={7} minHeight={'100%'} height={'90vh'}>
					<Grid container item xs={12} md={12} height={'60vh'}>
						<Grid
							container item xs={6} md={5}
							bgcolor={'background.default'} borderLeft={0} borderTop={0}
							height={'60vh'} borderColor={'divider'}
						>
							<Grid
								item xs={6} md={12} padding={1}
								bgcolor={'background.default'} borderLeft={0} borderTop={0}
								height={'40vh'} border={2} borderColor={'divider'}
							>
								<PieChartComponent
									title={"ENTRADAS DAS OCORRÊNCIAS"}
									data={dash.entrada_ocorrencias_chart.data}
									other={dash.entrada_ocorrencias_chart.other}
									direction={'column'}
								/>
							</Grid>
							<Grid
								item xs={6} md={12} padding={1}
								bgcolor={'background.default'} borderLeft={0} borderTop={0}
								height={'20vh'} border={2} borderColor={'divider'}
							>
								<PieChartComponent
									title={"GRAVIDADE DAS OCORRÊNCIAS"}
									data={dash.gravidade_ocorrencias_chart.data}
									other={dash.gravidade_ocorrencias_chart.other}
									radius={40}
								/>
							</Grid>
						</Grid>
						<Grid
							container item xs={6} md={7}
							bgcolor={'background.default'} borderLeft={0} borderTop={0}
							height={'60vh'} borderColor={'divider'}
						>
							<Grid
								item xs={6} md={12} padding={1}
								bgcolor={'background.default'} borderLeft={0} borderTop={0}
								height={'25vh'} border={2} borderColor={'divider'}
							>
								<VerticalBarChartComponent
									data={dash.manifestacoes_assunto_chart.data}
									title={"QUANTIDADE DE MANIFESTAÇÕES POR ASSUNTO"}
									chartMargin={{
										top: 0,
										right: 0,
										left: 20,
										bottom: 10
									}} withoutLegend hideXAxis
								/>
							</Grid>
							<Grid
								item xs={6} md={12} padding={1}
								bgcolor={'background.default'} borderLeft={0} borderTop={0}
								height={'35vh'} border={2} borderColor={'divider'}
							>
								<VerticalBarChartComponent
									data={dash.manifestacoes_secretaria_chart.data}
									title={"QUANTIDADE DE MANIFESTAÇÕES POR SECRETARIA"}
									chartMargin={{
										top: 0,
										right: 0,
										left: 20,
										bottom: 10
									}} withoutLegend hideXAxis
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'} border={2} borderColor={'divider'}
						height={'30vh'}
					>
						<HorizontalBarChartComponent
							data={dash.atendimento_tempo_chart.data}
							title={"OCORRÊNCIAS COMPARANDO COM PERíODO ANTERIOR"}
							names={['PERÍODO ANTERIOR', 'PERÍODO ATUAL']}
						/>
					</Grid>
				</Grid>
				<Grid container item xs={12} md={5} minHeight={'90vh'}>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'}
						height={'50%'} border={2} borderColor={'divider'}
					>
						<MapComponent
							textColor={'text.primary'}
							title={'OCORRÊNCIA POR LOCALIDADE'}
							markers={dash.locais.data}
						// withLegend legend='left' legendComponent={
						// 	<Box style={{ height: '100%' }}>
						// 		<Typography textAlign={"center"} fontSize={10} color={'text.primary'}>QUANTIDADE DE OCORRÊNCIAS</Typography>
						// 	</Box>
						// }
						/>
					</Grid>
					<Grid
						item xs={6} md={12} padding={1}
						bgcolor={'background.default'}
						height={'50%'} border={2} borderColor={'divider'}
					>
						<VerticalBarChartComponent
							data={dash.bairros_manifestacao_chart.data}
							title={"BAIRROS COM MAIS MANIFESTAÇÕES"}
							chartMargin={{
								top: 0,
								right: 0,
								left: 20,
								bottom: 10
							}} withoutLegend hideXAxis
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
