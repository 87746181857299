import { Grid } from '@mui/material';
import { defaultSegurancaData } from '../fakeData/segurancaData';
import DashHeader from '../components/DashHeader';
import CustomCard from '../components/CustomCard';
import MapComponent from '../components/map/MapComponent';
import VerticalBarChartComponent from '../components/charts/VerticalBarChartComponent';
import LineChartComponent from '../components/charts/LineChartComponent';
import CustomTable from '../components/CustomTable';
import { SegurancaType } from '../types/SegurancaType';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../constants/Link';
import { usePeriodContext } from '../provider/PeriodProvider';
import moment from 'moment';

export default function SegurancaDash() {
	const { start, end } = usePeriodContext();
	const [dash, setDash] = useState<SegurancaType>(defaultSegurancaData)

	useEffect(() => {
		axios.get(`${ApiUrl}/dashboard/seguranca?filter=1${start ? `&start=${moment(start).format('YYYY-MM-DD')}` : ''}${end ? `&end=${moment(end).format('YYYY-MM-DD')}` : ''}`).then((response) => {
			if (response?.data?.success) {
				setDash(response?.data?.data)
			}
		})
	}, [start, end])

	return (
		<Grid container minHeight={'100vh'} overflow={'hidden'} bgcolor={'background.default'}>
			<Grid container item md={12} bgcolor={'background.default'} height={"10vh"}>
				<DashHeader title={dash.title} insights={dash.insights} />
			</Grid>
			<Grid container item xs={6} md={2} borderColor={'divider'} height={"90vh"}>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.qualidade.title ?? "QUALIDADE DA SEGURANÇA"}
						value={dash.qualidade.value}
						icon={dash.qualidade.icon}
						color={dash.qualidade.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.total_ocorrencias.title ?? "TOTAL DE OCORRÊNCIAS"}
						value={dash.total_ocorrencias.value}
						icon={dash.total_ocorrencias.icon}
						color={dash.total_ocorrencias.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.total_denuncias.title ?? "TOTAL DE DENÚNCIAS"}
						value={dash.total_denuncias.value}
						icon={dash.total_denuncias.icon}
						color={dash.total_denuncias.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.total_bo.title ?? "TOTAL DE B.O."}
						value={dash.total_bo.value}
						icon={dash.total_bo.icon}
						color={dash.total_bo.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.total_pessoas.title ?? "PESSOAS COM PROTEÇÃO RÁPIDA"}
						value={dash.total_pessoas.value}
						icon={dash.total_pessoas.icon}
						color={dash.total_pessoas.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.total_escolas.title ?? "ESCOLAS COM PROTEÇÃO RÁPIDA"}
						value={dash.total_escolas.value}
						icon={dash.total_escolas.icon}
						color={dash.total_escolas.color}
					/>
				</Grid>
				<Grid
					item xs={6} md={12} border={2}
					display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
					bgcolor={'background.default'} borderColor={'divider'} padding={1}
				>
					<CustomCard
						title={dash.total_cameras.title ?? "CÃMERAS DE SEGURANÇA PÚBLICA"}
						value={dash.total_cameras.value}
						icon={dash.total_cameras.icon}
						color={dash.total_cameras.color}
					/>
				</Grid>
			</Grid>

			<Grid
				container item xs={6} md={10}
				bgcolor="#c9c9c9" borderColor={'divider'}
				display={'flex'} flexDirection={'column'} justifyContent={'space-between'} maxHeight={'90vh'}
			>
				<Grid
					container item xs={6} md={12} maxHeight={'70%'}
					bgcolor={'background.default'} borderColor={'divider'}
				>
					<Grid
						container item xs={6} md={5}

						bgcolor={'background.default'} borderColor={'divider'}
					>
						<Grid
							container item xs={6} md={12}
							bgcolor={'background.default'} borderColor={'divider'}
							height={'25%'}
						>
							<Grid
								item xs={6} md={6}
								bgcolor={'background.default'} borderLeft={0} borderTop={0}
								border={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
								borderColor={'divider'}
							>

								<CustomCard
									title={dash.total_pontos_risco.title ?? "TOTAL DE PONTOS DE RISCO"}
									value={dash.total_pontos_risco.value}
									icon={dash.total_pontos_risco.icon}
									color={dash.total_pontos_risco.color}
								/>

							</Grid>
							<Grid
								item xs={6} md={6}
								bgcolor={'background.default'} borderLeft={0} borderTop={0}
								border={2} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
								borderColor={'divider'}
							>

								<CustomCard
									title={dash.total_alerta_protecao.title ?? "TOTAL DE ALERTAS PARA PROTEÇÃO RÁPIDA"}
									value={dash.total_alerta_protecao.value}
									icon={dash.total_alerta_protecao.icon}
									color={dash.total_alerta_protecao.color}
								/>

							</Grid>
						</Grid>
						<Grid
							item xs={6} md={12} border={2}
							bgcolor={'background.default'} borderColor={'divider'}
							height={'25%'} padding={1}
						>
							<LineChartComponent
								data={dash.ocorrencia_tempo_chart.data}
								title={"OCORRÊNCIAS POR PERÍODO"}
								chartMargin={{
									top: 10,
									right: 30,
									left: 0,
									bottom: 0
								}} withoutLegend
							/>
						</Grid>
						<Grid
							item xs={6} md={12} border={2}
							bgcolor={'background.default'} borderColor={'divider'}
							height={'50%'} padding={1}
						>
							<LineChartComponent
								data={dash.protecao_tempo_chart.data}
								title={"ACIONAMENTOS DA PROTEÇÃO RÁPIDA POR PERíODO"}
								chartMargin={{
									top: 10,
									right: 30,
									left: 0,
									bottom: 10
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						item xs={6} md={7} border={2}
						display={'flex'} flexDirection={'column'}
						bgcolor={'background.default'} borderColor={'divider'} padding={1}
					>
						<MapComponent
							textColor={'text.primary'}
							title={'MAPA DE QUALIDADE DAS UBSs E HOSPITAIS'}
							markers={dash.locais.data}
						//withLegend legend='full'
						/>
					</Grid>
				</Grid>
				<Grid
					container item xs={6} md={12} maxHeight={'30%'}
					bgcolor={'background.default'} borderColor={'divider'}
				>
					<Grid
						item xs={6} md={4} border={2}
						display={'flex'} flexDirection={'column'}
						bgcolor={'background.default'} borderColor={'divider'} padding={1}
					>
						<CustomTable
							data={dash.bases_veiculos_table.data}
							columns={dash.bases_veiculos_table.columns}
							title={'BASES VEÍCULOS E AGENTES DISPONÍVEIS'}
						/>
					</Grid>
					<Grid
						item xs={6} md={4} border={2}
						display={'flex'} flexDirection={'column'}
						bgcolor={'background.default'} borderColor={'divider'} padding={1}
					>
						<VerticalBarChartComponent
							data={dash.locais_protecao_chart.data}
							title={"LOCAIS COM MAIOR ACIONAMENTO DA PROTEÇÃO RÁPIDA"}
							chartMargin={{
								bottom: 0,
								top: 10,
								left: 20,
								right: 0
							}}
							withoutLegend hideXAxis
						/>
					</Grid>
					<Grid
						item xs={6} md={4} border={2}
						display={'flex'} flexDirection={'column'}
						bgcolor={'background.default'} borderColor={'divider'} padding={1}
					>
						<VerticalBarChartComponent
							data={dash.bairros_protecao_chart.data}
							title={"BAIRROS COM MAIOR ACIONAMENTO DA PROTEÇÃO RÁPIDA"}
							chartMargin={{
								bottom: 0,
								top: 10,
								left: 20,
								right: 0
							}}
							withoutLegend hideXAxis
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
