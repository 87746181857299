import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography
} from '@mui/material'

interface CustomTableProps {
    data: any[]
    columns: { name: string; key: string }[]
    title?: string
    withColor?: boolean
    withColumns?: boolean
}

export default function CustomTable({ data, columns, title, withColor = false, withColumns = true }: CustomTableProps) {
    return (
        <>
            {title ? <Typography textAlign={"left"} fontSize={10} color={'text.primary'}>{title}</Typography> : null}
            <TableContainer style={{ height: '100%', overflowX: 'hidden' }}>
                <Table sx={{ height: '100%', overflowX: 'hidden' }} size="small">
                    <TableHead>
                        <TableRow>
                            {
                                withColor ? <TableCell align={"left"}></TableCell> : null
                            }
                            {withColumns && columns.map((column, index) => (
                                <TableCell align={index === 0 ? "left" : index === columns.length - 1 ? "right" : "center"} key={index}>{column.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row: any, rowIndex) => (
                            <TableRow
                                key={rowIndex}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {
                                    withColor ? <TableCell align={"left"} style={{ backgroundColor: row.color, border: '2px solid white', width: '.125rem' }}></TableCell> : null
                                }
                                {columns.map((column, columnIndex) => (
                                    <TableCell title={row[column.key]} sx={{ maxWidth: '5rem', wordWrap: 'break-word' }} key={columnIndex} align={columnIndex === 0 ? "left" : columnIndex === columns.length - 1 ? "right" : "center"} style={{ fontSize: 12, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                        {row[column.key]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}