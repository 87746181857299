import {
    ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis
    , YAxis
    , Tooltip
    , Legend, Rectangle
} from "recharts";
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles'
interface MarginProps {
    top: number
    right: number
    left: number
    bottom: number
}
interface BarChartComponentProps {
    data: any
    title: string
    barKey?: string
    barName?: string
    categoryKey?: string
    cartesianGrid?: boolean
    hideAxis?: boolean
    withoutLegend?: boolean
    color?: string
    chartMargin?: MarginProps
    hideXAxis?: boolean
    hideYAxis?: boolean
    withLabel?: boolean
}

const CustomizedLabel = (props: any) => {
    const { x, height, y, value, color } = props;
    return (
        <text
            x={x - 10}
            y={height / 2 + y}
            dy={5}
            fontSize='14'
            fill={color}
            textAnchor="middle"
        >
            {value}
        </text>
    )
}

export default function VerticalBarChartComponent({
    data, title, cartesianGrid = true, hideAxis = false, hideXAxis = false, hideYAxis = false,
    barKey = "uv", categoryKey = "name", barName = "Valor/Quantidade",
    withLabel = true, withoutLegend = false, color = '#82ca9d', chartMargin = {
        top: 30,
        right: 30,
        left: 20,
        bottom: 30
    } }: BarChartComponentProps) {
    const theme = useTheme()

    return (
        <>
            <Typography textAlign={"left"} fontSize={10} color={'text.primary'}>{title}</Typography>
            <ResponsiveContainer width="100%" height="100%" >
                <BarChart width={500} height={300} data={data} layout="vertical" margin={chartMargin}>
                    {cartesianGrid ? <CartesianGrid horizontal={false} strokeDasharray="3 3" /> : null}
                    <Bar dataKey={barKey} name={barName} background={{ fill: theme.palette.background.paper }} label={withLabel ? <CustomizedLabel color={theme.palette.text.primary} fontFamily={theme.typography.fontFamily} /> : <></>} fill={color} activeBar={<Rectangle fill="gold" stroke="purple" />} />
                    <XAxis type="number" hide={hideXAxis ? true : hideAxis} />
                    <YAxis
                        type="category" hide={hideYAxis ? true : hideAxis} dataKey={categoryKey}
                        tickLine={false} mirror tickMargin={0}
                        tick={{ width: 500, fontSize: 12, fontFamily: theme.typography.fontFamily, fill: theme.palette.text.primary }} /> {/* Increase width if text overflow */}
                    <Tooltip />
                    {withoutLegend ? null : <Legend />}
                </BarChart>
            </ResponsiveContainer>
        </>
    )
}
