import {
    ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis
    , YAxis
    , Tooltip
    , Legend, Rectangle
} from "recharts";
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles'

interface BarChartComponentProps {
    data: any
    title: string
    cartesianGrid?: boolean
    hideAxis?: boolean
    withoutLegend?: boolean
    names?: string[]
}

export default function HorizontalBarChartComponent({ data, title, names = [] }: BarChartComponentProps) {
    const theme = useTheme()

    return (
        <>
            <Typography textAlign={"left"} fontSize={10} color={'text.primary'}>{title}</Typography>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis tick={{ width: 500, fontSize: 12, fontFamily: theme.typography.fontFamily, fill: theme.palette.text.primary }} dataKey="name" />
                    <YAxis tick={{ width: 500, fontSize: 12, fontFamily: theme.typography.fontFamily, fill: theme.palette.text.primary }} />
                    <Tooltip />
                    <Legend
                        formatter={(value, entry, index) => <span style={{ width: 500, fontSize: 12, fontFamily: theme.typography.fontFamily, fill: theme.palette.text.primary }}>{value}</span>}
                    />
                    <Bar dataKey="pv" name={names[0]} fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                    <Bar dataKey="uv" name={names[1]} fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                </BarChart>
            </ResponsiveContainer>
        </>
    )
}