export const defaultSegurancaData = {
	title: "SEGURANÇA",
	insights: [],
	qualidade: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	total_ocorrencias: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	total_denuncias: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	total_bo: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	total_pessoas: {
		title: null,
		value: "0",
		icon: "arrow_drop_down",
		color: "warning"
	},
	total_escolas: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	total_cameras: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	total_pontos_risco: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	total_alerta_protecao: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	ocorrencias: [
		{
			title: "TOTAL DE OCORRÊNCIAS",
			value: "0",
			icon: "arrow_drop_down",
			color: "warning",
		}, {
			title: "TOTAL PENDENTES",
			value: "0",
			icon: "",
			color: "",
		}, {
			title: "TOTAL RESOLVIDAS",
			value: "0",
			icon: "",
			color: "",
		}, {
			title: "TOTAL EXPIRADAS",
			value: "0",
			icon: "",
			color: "",
		},
	],
	ocorrencia_tempo_chart: {
		data: [
			{
				name: "Janeiro",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Fevereiro",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Março",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Abril",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Maio",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Junho",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Julho",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			}
		]
	},
	protecao_tempo_chart: {
		data: [
			{
				name: "Janeiro",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Fevereiro",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Março",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Abril",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Maio",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Junho",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Julho",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			}
		]
	},
	locais_protecao_chart: {
		data: []
	},
	bairros_protecao_chart: {
		data: []
	},
	entrada_ocorrencias_chart: {
		data: [
			{ name: 'APP', value: 0 },
			{ name: 'PORTAL WEB', value: 0 },
			{ name: 'PESSOALMENTE', value: 0 },
			{ name: 'OUTROS', value: 0 },
		]
	},
	gravidade_ocorrencias_chart: {
		data: [
			{ name: 'ALTA', value: 0 },
			{ name: 'MÉDIA', value: 0 },
			{ name: 'BAIXA', value: 0 },
		]
	},
	bases_veiculos_table: {
		columns: [
			{ name: 'Circulação', key: 'circulacao' },
			{ name: 'Parado', key: 'lotacao' },
			{ name: 'Manutenção', key: 'manutencao' },
			{ name: 'Sub-Total', key: 'subtotal' },
		],
		data: []
	},
	locais: {
		data: []
	},
}