export const defaultSaudeData = {
	title: "SAÚDE",
	backgroundColor: "#e6e6e6",
	gridColor: "#c7c7c7",
	textColor: "#242424",
	insights: [],
	atendimento: {
		qualidade: {
			value: null,
			icon: "arrow_drop_up",
			color: "success"
		},
		tempo_medio_por_ubs: {
			value: null,
			icon: "arrow_drop_down",
			color: "warning"
		},
		media_por_ubs: {
			value: null,
			icon: "arrow_drop_down",
			color: "warning"
		}
	},
	total_atendimentos: [
		{
			title: "TOTAL DE ATENDIMENTOS",
			value: "0",
			icon: "arrow_drop_down",
			color: "warning",
		}, {
			title: "AGENDAMENTOS",
			value: "0",
			icon: "arrow_drop_down",
			color: "warning",
		}, {
			title: "CASOS EXPONTÂNEOS",
			value: "0",
			icon: "arrow_drop_down",
			color: "warning",
		},
	],
	profissionais_saude: [
		{
			title: "TOTAL DE PROFISSIONAIS DA SAÚDE",
			value: "0",
			icon: "",
			color: "",
		}, {
			title: "MÉDICOS",
			value: "0",
			icon: "",
			color: "",
		}, {
			title: "ENFERMEIROS",
			value: "0",
			icon: "",
			color: "",
		}, {
			title: "OUTRAS FUNÇÕES",
			value: "0",
			icon: "",
			color: "",
		},
	],
	atendimento_tempo_chart: {
		data: [
			{
				name: "Janeiro",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Fevereiro",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Março",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Abril",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Maio",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Junho",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			},
			{
				name: "Julho",
				total: 0,
				agendamentos: 0,
				expontaneos: 0,
				amt: 0
			}
		]
	},
	ubs_tempo_chart: {
		data: []
	},
	medicamentos_chart: {
		data: []
	},
	idade_genero_chart: {
		homem_data: [
			{
				name: "10 - 15",
				uv: -0,
			},
			{
				name: "20 - 30",
				uv: -0,
			},
			{
				name: "30 - 50",
				uv: -0,
			},
			{
				name: "50 - 70",
				uv: -0,
			},
			{
				name: "70 +",
				uv: -0,
			},

		], mulher_data: [
			{
				name: "10 - 15",
				uv: 0,
				fill: '#c94cac'
			},
			{
				name: "20 - 30",
				uv: 0,
				fill: '#c94cac'
			},
			{
				name: "30 - 50",
				uv: 0,
				fill: '#c94cac'
			},
			{
				name: "50 - 70",
				uv: 0,
				fill: '#c94cac'
			},
			{
				name: "70 +",
				uv: 0,
				fill: '#c94cac'
			},
		]
	},
	motivo_atendimento_chart: {
		data: []
	},
	locais: {
		data: []
	},
}