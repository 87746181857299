export const defaultMobilidadeData = {
	title: "MOBILIDADE",
	insights: [],
	qualidade: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	media_passageiros: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	tempo_transportes: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	tempo_atraso: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	total_veiculos: {
		title: null,
		value: "0",
		icon: "arrow_drop_down",
		color: "warning"
	},
	acidentes: {
		title: null,
		value: "0",
		icon: "arrow_drop_up",
		color: "success"
	},
	valor_passagem: {
		title: null,
		value: "R$ 5,00",
		icon: "arrow_drop_up",
		color: "success"
	},
	media_uso_chart: {
		data: []
	},
	transportes_disponiveis_chart: {
		data: [
			// { name: 'APP', value: 0 },
			// { name: 'PORTAL WEB', value: 0 },
			// { name: 'PESSOALMENTE', value: 0 },
			// { name: 'OUTROS', value: 0 },
		]
	},
	linhas_table: {
		columns: [
			{ name: 'Linha', key: 'linha' },
			{ name: 'Lotação', key: 'lotacao' },
			{ name: 'Atraso', key: 'atraso' },
		],
		data: []
	},
	transportes_disponiveis_table: {
		columns: [
			{ name: 'Circulação', key: 'circulacao' },
			{ name: 'Parado', key: 'lotacao' },
			{ name: 'Manutenção', key: 'manutencao' },
			{ name: 'Sub-Total', key: 'subtotal' },
		],
		data: []
	},
	locais: {
		data: []
	},
}